const {campaign,globalDataListener, areSameDeepIgnore, getDirectDownloadUrl,httpAuthRequestWithRetry} = require('../lib/campaign.js');
const {marketplace} = require('../lib/marketplace.js');
const {firebase} = require("./firebase.jsx");
import { getStorage, ref, uploadBytes} from "firebase/storage";
const React = require('react');
const {displayMessage} = require('./notification.jsx');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {SelectVal, CheckVal, TextLabelEdit, AskYesNo, ClipboardCopy, DeleteWithConfirm,PriceVal,TextVal,SelectMultiTextVal,TextBasicEdit,getDateStrFromDate,DateVal} = require('./stdedit.jsx');
const {joinCommaAnd,defaultSettings,defaultStorylines,packageGenres, packageTypes,zeroTo20} = require('../lib/stdvalues.js');
const {ListFilter} = require('./listfilter.jsx');
const {PackagePicker,AdminPackageDialog,resizeAndCropImage,thumbnailSize,PackageDialog,getPkgEntries,excludeTypes} = require('./packages.jsx');
const {BuyButton, addToCart, isInCart,ProductPrice,getPopularity,dollarProducts} = require('./purchase.jsx');
const {FeaturedProductSettings} = require('./banner.jsx');
const {ArtPicker} = require('./renderart.jsx');

const shardSubscriptionList=["2yarsenaw01v6pj7","0mgps243ynv32dvk","1lptzx3bblfjy4bm"];

class ProductsList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getAllProducts()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "products");
        globalDataListener.onChangeProducts(this.handleOnDataChange);
        marketplace.load();
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "products");
        globalDataListener.removeProductsListener(this.handleOnDataChange);
    }

	render() {
        const list = campaign.getProducts();
        return <div className="notecontent" key="products">
            {list.length?<ListFilter 
                list={list} 
                render={productRender} 
                filters={productFilters} 
                onClick={this.clickProduct.bind(this)} 
                showThumbnails
                border
            />:<div className="hk-well">
                Click new to create products for the marketplace.
            </div>}
        </div>;
    }

    clickProduct(name) {
        window.location.href = "/#products?id="+name;
    }
}

class ProductsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    render() {
        return <span>
            My Products
            <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.newProduct.bind(this)}>New</Button>
            <NewProduct open={this.state.show} onClose={this.hide.bind(this)}/>
        </span>;
    }

    newProduct() {
        this.setState({show:true});
    }

    hide() {
        this.setState({show:false});
    }
}

class NewProduct extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:"", purchaseType:"single", packages:{}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:"", purchaseType:"single", packages:{}});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const product = {};
            product.displayName = this.state.text;
            product.purchaseType = this.state.purchaseType;
            product.publisher = campaign.displayName;
            product.name = campaign.newUid();
            addPackagesToProduct(product, this.state.packages);
            campaign.updateCampaignContent("products",product);

            this.props.onClose();
            window.location.href = "/#products?id="+product.name;
        } else {
            this.props.onClose();
        }

        event.stopPropagation();
    };

    setPurchaseType(purchaseType) {
        this.setState({purchaseType});
    }

    setPackages(packages) {
        this.setState({packages});
    }

    setText(text) {
        this.setState({text});
    }

    render() {
        if (!this.props.open)
            return null;

        const name = this.state.text;
        const purchaseType = this.state.purchaseType;
        const packages = this.state.packages;
        const plist = Object.keys(packages).map(function (p){return campaign.getPackageInfo(p).name});
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Create Product</DialogTitle>
            <DialogContent>
                <TextVal text={this.state.text} fullWidth helperText="Product Name" onChange={this.setText.bind(this)}/>
                <div className="mb1 mt2" onClick={this.setPurchaseType.bind(this,"single")}>
                    <span className={purchaseType=="single"?"far fa-dot-circle pa1 hoverhighlight":"far fa-circle pa1 hoverhighlight"}/> One time Purchase
                </div>
                <div className="mb2" onClick={this.setPurchaseType.bind(this,"subscription")}>
                    <span className={purchaseType=="subscription"?"far fa-dot-circle pa1 hoverhighlight":"far fa-circle pa1 hoverhighlight"}/> Subscription Purchase
                </div>
                {plist.length?<div className="mb2">
                    Includes packages: {plist.join(", ")}
                </div>:null}
                <div className="hk-well">
                    A one time purchase will give access to packages in a single purchase, while a subscrition will bill the users on a monthly or yearly basis.
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showPackagePicker.bind(this)} color="primary">
                    Pick Packages
                </Button>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    Create
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <PackagePicker open={this.state.showPackagePicker} onClose={this.closePackagePicker.bind(this)} selected={this.state.packages}/>
        </Dialog>;
    }

    closePackagePicker(packages) {
        if (packages) {
            let text = this.state.text;
            if (!text) {
                text = Object.keys(packages).map(function (p){return campaign.getPackageInfo(p).name}).join(", ");
            }
            this.setState({showPackagePicker:false, packages, text});
        } else {
            this.setState({showPackagePicker:false});
        }
    }

    showPackagePicker() {
        this.setState({showPackagePicker:true});
    }
}

function addPackagesToProduct(product, packages) {
    const includedPackages = (product.includedPackages||[]).concat([]);

    for (let i=(includedPackages.length-1); i>=0; i--) {
        if (!packages[includedPackages[i]]){
            const pkg = campaign.getPackageInfo(includedPackages[i]);
            includedPackages.splice(i,1);
            if (pkg && pkg.thumbnail) {
                if (product.thumb == pkg.thumbnail) {
                    product.thumb=null;
                } else {
                    const pos = (product.altThumbs||[]).indexOf(pkg.thumbnail);
                    if (pos >=0) {
                        product.altThumbs = (product.altThumbs||[]).concat([]);
                        product.altThumbs.splice(pos,1);
                    }
                }
            }
        }
    }

    for (let i in packages) {
        if (!includedPackages.includes(i)) {
            const pkg = campaign.getPackageInfo(i);

            includedPackages.push(i);
            product.type = mergeList(product.type, pkg.type);
            product.genre = mergeList(product.genre, pkg.genre);
            product.setting = mergeList(product.setting, pkg.setting);
            product.storyline = mergeList(product.storyline, pkg.storyline);
            if (pkg.ruleset) {
                product.rulesets = mergeList(product.rulesets, pkg.ruleset);
            }
            if (pkg.startLevel) {
                product.startLevel = Math.min(product.startLevel||20, pkg.startLevel);
            }
            if (pkg.endLevel) {
                product.endLevel = Math.max(product.endLevel||0, pkg.endLevel);
            }
            if (pkg.description) {
                product.description = (product.description||"")+pkg.description;
            }

            if (pkg.thumbnail) {
                if (!product.thumb) {
                    product.thumb=pkg.thumbnail;
                } else {
                    product.altThumbs = (product.altThumbs||[]).concat([pkg.thumbnail]);
                }
            }

        }
    }

    product.includedPackages = includedPackages;
    updateSummary(product);
}

function updateSummary(product) {
    product.summary = {};
    for (let i in product.includedPackages) {
        const pkg = campaign.getPackageInfo(product.includedPackages[i]);

        if (pkg) {
            const summary = pkg.summary;
            for (let x in summary) {
                product.summary[x] = (product.summary[x]||0)+summary[x];
            }
        }
    }
}



function mergeList(base, addStr) {
    const add = addStr?addStr.split(",").map(function (a){return a.trim()}):[];
    const res=(base||[]).concat([]);
    for (let i in add) {
        const a = add[i];
        if (!res.includes(a)) {
            res.push(a);
        }
    }
    if (!res.length) {
        return null;
    }
    return res;
}

class UnlockKeys extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:true};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadKeys();
        }
    }

    async loadKeys(refresh) {
        this.setState({loading:true, key1:null, key2:null});
        try {
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=getapikey", JSON.stringify({
                refresh:refresh||false
            }));
            const response = JSON.parse(responseText);
            this.setState({key1:response.key_secret_1, key2:response.key_secret_2});
        } catch (error) {
            displayMessage("Could not get secrets: "+error.message);
        }

        this.setState({loading:false});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const userId = campaign.currentUser.uid;

        return <Dialog
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Key Unlock Secrets</DialogTitle>
            <DialogContent>
                <div className="hk-well">Secret keys can be used to make API requests to generate unlock codes.</div>
                <table>
                    <tbody>
                        <tr><td>User ID&nbsp;</td><td><ClipboardCopy text={userId}>{userId}</ClipboardCopy></td></tr>
                        {this.state.key1?<tr><td>Current&nbsp;</td><td><ClipboardCopy text={this.state.key1}>{this.state.key1}</ClipboardCopy></td></tr>:null}
                        {this.state.key2?<tr><td>Previous&nbsp;</td><td><ClipboardCopy text={this.state.key2}>{this.state.key2}</ClipboardCopy></td></tr>:null}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.loadKeys.bind(this, true)} color="primary">
                    Rotate
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading Secrets...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }
}

class ProductDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeProducts(this.handleOnDataChange);
    }

    componentWillUnmount() {
        globalDataListener.removeProductsListener(this.handleOnDataChange);
    }

    onDataChange() {
        if (!this.props.product && this.props.productId) {
            this.loadProduct();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            if (!this.props.product && this.props.productId) {
                this.loadProduct();
            }
        }
    }

    async loadProduct() {
        const t=this;
        this.setState({loading:true});
        try {
            const product = await marketplace.loadProductInfo(this.props.productId);
            this.setState({product});
        } catch (error) {
            displayMessage("Could not load product: "+error.message,function () {t.props.onClose()});
        }

        this.setState({loading:false});
    }



	render() {
        if (!this.props.open) {
            return null;
        }

        const product=this.props.product||this.state.product||{};

        return <Dialog
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Product</DialogTitle>
            <DialogContent>
                <ProductDisplay product={product} readonly={!this.props.purchasable} useCampaign={this.props.useCampaign} noGift={this.props.noGift} />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Loading Product...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }
}


class ProductDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getAllProducts()})
    }

    componentDidMount() {
        const t=this;
        marketplace.load().then(function (){
            t.setState({loadedProducts:true});
        },function (error) {
            displayMessage("Could not load products. "+error.message);
        });
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "products");
        globalDataListener.onChangeProducts(this.handleOnDataChange);

    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "products");
        globalDataListener.removeProductsListener(this.handleOnDataChange);
    }

	render() {
        const product = campaign.getProductInfo(this.props.product);
        if (!product) {
            return <div className="pa2">Product not found</div>;
        }
        const includedPackages = (product.includedPackages||[]);
        const creditPackages = (product.creditPackages||[]);
        const subscriptionUpgrade = (product.subscriptionUpgrade||[]);
        const recommendedProducts = (product.recommendedProducts||[]);
        const subscriptionDiscounts = (product.subscriptionDiscounts||{});
        const products = marketplace.products||{};
        const packageCredits = (product.packageCredits||{});
        const subscription = (product.purchaseType=="subscription");
        const ilist = [];
        const clist = [];
        const sdlist = [];
        const recommend = [];
        const upgrade = [];

        updateSummary(product);

        for (let i in includedPackages) {
            const pkg = campaign.getPackageInfo(includedPackages[i]);
            if (pkg) {
                ilist.push(<tr key={i}>
                    <td className="hoverhighlight" onClick={this.onClickPackage.bind(this, pkg)}>
                        {pkg.name}
                    </td>
                    {!subscription?<td className="mw2"><TextVal inputProps={{className:"tr"}} text={packageCredits[pkg.id]||0} isNum onChange={this.setPackageCredits.bind(this,pkg.id)} fullWidth/></td>:null}
                </tr>);
            }
        }

        if (subscription) {
            for (let i in subscriptionUpgrade) {
                const s = subscriptionUpgrade[i];
                const p = products[s];
                if (p) {
                    upgrade.push(<tr key={i}><td><a onClick={this.setShowProduct.bind(this,p)}>{p.displayName}</a></td></tr>);
                }
            }
        } else {
            for (let i in creditPackages) {
                const pkg = campaign.getPackageInfo(creditPackages[i]);
                if (pkg) {
                    clist.push(<tr key={i}>
                        <td className="hoverhighlight" onClick={this.onClickPackage.bind(this, pkg)}>
                            {pkg.name}
                        </td>
                        {!subscription?<td className="mw2"><TextVal inputProps={{className:"tr"}} text={packageCredits[pkg.id]||0} isNum onChange={this.setPackageCredits.bind(this,pkg.id)} fullWidth/></td>:null}
                    </tr>);
                }
            }
            for (let s in subscriptionDiscounts) {
                const p = products[s];
                if (p) {
                    sdlist.push(<tr key={s}>
                        <td className="hoverhighlight" onClick={this.setShowProduct.bind(this,p)}>
                            {p.displayName}
                        </td>
                        <td className="mw2"><TextVal inputProps={{className:"tr"}} text={subscriptionDiscounts[s]||0} isNum onChange={this.setSubscriptionDiscounts.bind(this,s)} fullWidth/></td>
                    </tr>);
                }
            }
        }

        for (let i in recommendedProducts) {
            const r = recommendedProducts[i];
            const p = products[r];
            if (p) {
                recommend.push(<tr key={i}><td><a onClick={this.setShowProduct.bind(this,p)}>{p.displayName}</a></td></tr>);
            }
        }

        return <div className="pa1 overflow-y-auto overflow-x-hidden h-100 flex flex-wrap">
            <div className="w-100 mw8 flex1">
                <div><a href="/#products"><span className="fas fa-angle-left"/> back to products</a></div>
                <div className="f3 mb1 pa1">
                    Product Preview {isDirtyProduct(product)?<span className="red">Needs publishing </span>:null}
                    <Button className="ml2" onClick={this.showDetailsEdit.bind(this, true)} color="primary" size="small" variant="outlined">
                        Edit Description
                    </Button> 
                    <Button className="ml2" onClick={this.showImagesEdit.bind(this, true)} color="primary" size="small" variant="outlined">
                        Edit Images
                    </Button>
                    <Button className="ml2" onClick={this.publishPackage.bind(this)} color="primary" size="small" variant="outlined">
                        Publish
                    </Button>
                    <Button className="ml2 mr2" onClick={this.showUnlockKey.bind(this, true)} color="primary" size="small" variant="outlined">
                        Get Unlock Key
                    </Button>
                    <DeleteWithConfirm useButton name={product.displayName} onClick={this.deleteProduct.bind(this)} color="primary" size="small" variant="outlined"/>
                </div>
                <div className="titleborder ba pa1">
                    <ProductDisplay product={product} readonly/>
                </div>
            </div>
            <div className="minw55 mw7 flex2 pa1">
                <div className="f2 bb titleborder titlecolor">Pricing</div>
                {subscription?<div>
                    <div>
                        <PriceVal className="mr2" price={product.monthlyPrice} onChange={this.setValue.bind(this,"monthlyPrice")} label="Monthly Price"/>
                        <PriceVal price={product.monthlySalePrice} onChange={this.setValue.bind(this,"monthlySalePrice")} label="Monthly Sale Price" className="mr2"/>
                    </div>
                    <div>
                        <PriceVal className="mr2" price={product.yearlyPrice} onChange={this.setValue.bind(this,"yearlyPrice")} label="Yearly Price"/>
                        <PriceVal price={product.yearlySalePrice} onChange={this.setValue.bind(this,"yearlySalePrice")} label="Yearly Sale Price"/>
                    </div>
                </div>:<div>
                    <PriceVal className="mr2" price={product.listPrice} onChange={this.setValue.bind(this,"listPrice")} label="List Price"/>
                    <PriceVal price={product.salePrice} onChange={this.setValue.bind(this,"salePrice")} label="Sale Price"/>
                </div>}
                <div className="f2 bb titleborder titlecolor mb1 mt2">Included Packages</div>
                <div>
                    <Button onClick={this.showPackagePicker.bind(this)} color="primary" variant="outlined" size="small">
                        Pick Packages
                    </Button>
                    {(!subscription&&ilist.length)?<div className="hk-well mt1">If packages may be purchased with other products, you may enter a credit percentage to discount the product when the user already owns the package.</div>:null}
                </div>
                <div  className="stdlist mv1">
                    {ilist.length?<table>
                        <tbody>
                            <tr className="b"><td className="w-100">Package</td>{!subscription?<td>Credit&nbsp;%</td>:null}</tr>
                            {ilist}
                        </tbody>
                    </table>:<div className="pa1">No Packages Included</div>}
                </div>
                {subscription?<div>
                    <div className="f2 bb titleborder titlecolor mb1 mt2">Bonus Packages</div>
                    <div className="hk-well">
                        <Button className="mr1" onClick={this.showBonusPackagePicker.bind(this)} color="primary" variant="outlined" size="small">Pick Bonus Packages</Button>
                        All current subscribers get an owned copy of these packages.  When updated, new ones will be added for subscribers and users keep previous ones.
                    </div>
                    {this.getBonusPackages(product)}
                    <div className="mb2"/>
                    <div className="f2 bb titleborder titlecolor mb1 mt2">Upgradable Subscriptions</div>
                    <div className="hk-well">
                        <Button className="mr1" onClick={this.showPickSubscriptionUpgrade.bind(this, product)} color="primary" variant="outlined" size="small">Pick Subscriptions</Button>
                        If a user already has one of these subscriptions then this subscription will replace those when purchased.  The user will get credit for the previous subscription.
                    </div>
                    {upgrade.length?<div className="stdlist mv1"><table><tbody>{upgrade}</tbody></table></div>:null}
                    <div className="mb2"/>
                </div>:<div>
                    <div className="f2 bb titleborder titlecolor mb1 mt2">Credit Packages</div>
                    <div className="hk-well">
                        <Button className="mr1" onClick={this.showCreditPackagePicker.bind(this)} color="primary" variant="outlined" size="small">Pick Credit Packages</Button>
                        Credit packages are packages that are not included with the product, but that can give a discount toward the product.
                    </div>
                    <div  className="stdlist mv1">
                        {clist.length?<table>
                            <tbody>
                                <tr className="b"><td className="w-100">Package</td><td>Credit&nbsp;%</td></tr>
                                {clist}
                            </tbody>
                        </table>:<div className="pa1">No Credit Packages</div>}
                    </div>
                    <div className="mb2"/>
                    <div className="f2 bb titleborder titlecolor mb1 mt2">Subscription Discounts</div>
                    <div className="hk-well">
                        <Button className="mr1" onClick={this.showPickSubscriptionDiscount.bind(this, product)} color="primary" variant="outlined" size="small">Pick Subscriptions</Button>
                        Subscription discounts provide a discount on this product if the user has the subscription.  If the user has more than one of the subscriptions they will get the largest discount.
                    </div>
                    {sdlist.length?<div className="stdlist mv1">
                        <table>
                            <tbody>
                                <tr className="b"><td className="w-100">Product</td><td>Discount&nbsp;%</td></tr>
                                {sdlist}
                            </tbody>
                        </table>
                    </div>:null}
                </div>}
                <div className="f2 bb titleborder titlecolor mb1 mt2">
                    Royalty Sharing
                </div>
                <div className="hk-well mv1">
                    <Button onClick={this.showRoayaltyShare.bind(this)} color="primary" variant="outlined" size="small">
                        Allocate
                    </Button>
                    &nbsp;Share royalties with contributors to your product.
                </div>
                {this.getRoyaltyShare(product.royaltyShare)}
                <div className="mb2"/>
                <div className="f2 bb titleborder titlecolor mb1 mt2">Recommended Products</div>
                <div className="hk-well">
                    <Button className="mr1" onClick={this.showPickRecommendedProducts.bind(this, product)} color="primary" variant="outlined" size="small">Pick Products</Button>
                    These products will be recommended on the product details of this product.
                </div>
                {recommend.length?<div className="stdlist mv1"><table><tbody>{recommend}</tbody></table></div>:null}
                <div className="mb2"/>
                {subscription?null:this.getPartialPurchase(product)}
            </div>
            <ProductDetailsEdit product={product.name} open={this.state.showDetailsEdit} onClose={this.showDetailsEdit.bind(this,false)}/>
            <PickImages product={product} open={this.state.showImagesEdit} onClose={this.showImagesEdit.bind(this, false)}/>
            <PackagePicker open={this.state.showPackagePicker} onClose={this.closePackagePicker.bind(this)} selected={this.state.selected}/>
            <PackagePicker open={this.state.showCreditPackagePicker} onClose={this.closeCreditPackagePicker.bind(this)} selected={this.state.selected}/>
            <PackagePicker open={this.state.showBonusPackagePicker} onClose={this.closeBonusPackagePicker.bind(this)} selected={this.state.selected}/>
            <PackageDialog open={this.state.showPackage} pkg={this.state.showPackagePkg} onClose={this.onClosePackage.bind(this)} viewOnly/>
            <RoyaltyShare open={this.state.showRoyaltyShare} royaltyShare={product.royaltyShare} onClose={this.onCloseRoyaltyShare.bind(this)}/>
            <UnlockKey open={this.state.showUnlockKey} onClose={this.showUnlockKey.bind(this,false)} product_id={product.name}/>
            <DoPublish product={this.props.product} open={this.state.showPublish} onClose={this.setState.bind(this, {showPublish:false}, null, null)}/>
            <ProductsPicker open={this.state.showProductsPicker} selected={this.state.selectedProducts} onClose={this.pickProducts.bind(this)} selectSubscriptions={this.state.selectSubscriptions} selectOwned={this.state.selectOwned}/>
            <ProductsPicker open={this.state.showPickSubscriptionDiscount} selected={subscriptionDiscounts} onClose={this.pickSubcriptionDiscounts.bind(this)} selectSubscriptions/>
            <ProductDialog open={this.state.showProduct} product={this.state.showProduct} onClose={this.setShowProduct.bind(this,null)}/>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading Product...
                </DialogContent>
            </Dialog>

        </div>;
    }

    setShowProduct(showProduct) {
        this.setState({showProduct});
    }

    showUnlockKey(showUnlockKey) {
        this.setState({showUnlockKey});
    }

    showPickSubscriptionDiscount() {
        this.setState({showPickSubscriptionDiscount:true})
    }

    pickSubcriptionDiscounts(selected) {
        if (selected) {
            for (let i in selected) {
                if (selected[i]==1) {
                    selected[i]=10;
                }
            }
            this.setValue("subscriptionDiscounts", selected)
        }
        this.setState({showPickSubscriptionDiscount:false});
    }

    setSubscriptionDiscounts(pid, val) {
        const product = campaign.getProductInfo(this.props.product);
        const subscriptionDiscounts = Object.assign({},product.subscriptionDiscounts||{});

        subscriptionDiscounts[pid]=val;
        this.setValue("subscriptionDiscounts", subscriptionDiscounts);
    }

    setPackageCredits(pkg, val) {
        const product = campaign.getProductInfo(this.props.product);
        const packageCredits = Object.assign({},product.packageCredits||{});

        packageCredits[pkg]=val;
        this.setValue("packageCredits", packageCredits);
    }

    showRoayaltyShare() {
        this.setState({showRoyaltyShare:true});
    }

    onCloseRoyaltyShare(royaltyShare) {
        if (royaltyShare) {
            this.setValue("royaltyShare",royaltyShare);
        }
        this.setState({showRoyaltyShare:false});
    }

    getRoyaltyShare(royaltyShare) {
        if (!royaltyShare) {
            return null;
        }
        const list = [];

        for (let i in royaltyShare) {
            const r=royaltyShare[i];
            if (r.email || r.percent) {
                list.push(<tr key={i}>
                    <td>{r.email}</td>
                    <td className="tr">{r.percent||0}%</td>
                </tr>);
            }
        }
        if (list.length) {
            return <table className="stdlist">
                <tbody>
                    {list}
                </tbody>
            </table>
        }
        return null;
    }

    showPickSubscriptionUpgrade(product) {
        const selected = {};
        const su = product.subscriptionUpgrade||{};
        for (let i in su) {
            selected[su[i]]=true;
        }
        this.setState({showProductsPicker:true,selectedProducts:selected, productPickType:"subscriptionUpgrade", selectSubscriptions:true, selectOwned:true});
    }

    showPickRecommendedProducts(product) {
        const selected = {};
        const rp = product.recommendedProducts||{};
        for (let i in rp) {
            selected[rp[i]]=true;
        }
        this.setState({showProductsPicker:true,selectedProducts:selected, productPickType:"recommendedProducts", selectSubscriptions:false, selectOwned:false});
    }

    pickProducts(selected) {
        if (selected) {
            const vals =Object.keys(selected);
            this.setValue(this.state.productPickType, vals.length?vals:null)
        }
        this.setState({showProductsPicker:false});
    }

    publishPackage() {
        this.setState({showPublish:true});
        return;
    }

    async deleteProduct() {
        try {
            this.setState({loading:true});
            await httpAuthRequestWithRetry("POST", "/search?cmd=deleteproduct", JSON.stringify({
                product:this.props.product
            }))

            this.setState({loading:false});
            displayMessage("Product deleted");
            window.location.href = "/#products";
        } catch (err) {
            displayMessage("Error: "+err.message);
            this.setState({loading:false});
        }
    }

    setValue(prop,value) {
        const newProd = Object.assign({}, campaign.getProductInfo(this.props.product));
        switch (prop) {
            case "listPrice":
                if ((newProd.listPrice||0) == (newProd.salePrice||0) ) {
                    newProd.salePrice=value;
                }
                break;
            case "monthlyPrice":
                if ((newProd.monthlyPrice||0) == (newProd.monthlySalePrice||0) ) {
                    newProd.monthlySalePrice=value;
                }
                break;
            case "yearlyPrice":
                if ((newProd.yearlyPrice||0) == (newProd.yearlySalePrice||0) ) {
                    newProd.yearlySalePrice=value;
                }
                break;
        }
        newProd[prop]=value;
        campaign.updateCampaignContent("products",newProd);
    }

    showDetailsEdit(showDetailsEdit) {
        this.setState({showDetailsEdit});
    }

    showImagesEdit(showImagesEdit) {
        this.setState({showImagesEdit});
    }

    showPackagePicker() {
        const product = campaign.getProductInfo(this.props.product);
        const includedPackages = (product.includedPackages||[]).concat([]);
        const selected={};
        for (let i in includedPackages) {
            selected[includedPackages[i]]=true;
        }
        this.setState({showPackagePicker:true, selected});
    }

    closePackagePicker(packages) {
        if (packages) {
            const newProd = Object.assign({}, campaign.getProductInfo(this.props.product));
            addPackagesToProduct(newProd, packages);

            campaign.updateCampaignContent("products",newProd);
            this.setState({showPackagePicker:false});
        } else {
            this.setState({showPackagePicker:false});
        }
    }

    showCreditPackagePicker() {
        const product = campaign.getProductInfo(this.props.product);
        const creditPackages = (product.creditPackages||[]).concat([]);
        const selected={};
        for (let i in creditPackages) {
            selected[creditPackages[i]]=true;
        }
        this.setState({showCreditPackagePicker:true, selected});
    }

    closeCreditPackagePicker(packages) {
        if (packages) {
            const newProd = Object.assign({}, campaign.getProductInfo(this.props.product));
            const creditPackages = [];

            for (let i in packages) {
                creditPackages.push(i);
            }
        
            newProd.creditPackages = creditPackages;

            campaign.updateCampaignContent("products",newProd);
            this.setState({showCreditPackagePicker:false});
        } else {
            this.setState({showCreditPackagePicker:false});
        }
    }

    showBonusPackagePicker() {
        const product = campaign.getProductInfo(this.props.product);
        const bonusPackages = (product.bonusPackages||[]).concat([]);
        const selected={};
        for (let i in bonusPackages) {
            selected[bonusPackages[i]]=true;
        }
        this.setState({showBonusPackagePicker:true, selected});
    }

    closeBonusPackagePicker(packages) {
        if (packages) {
            const newProd = Object.assign({}, campaign.getProductInfo(this.props.product));
            const bonusPackages = [];

            for (let i in packages) {
                bonusPackages.push(i);
            }
        
            newProd.bonusPackages = bonusPackages;

            campaign.updateCampaignContent("products",newProd);
            this.setState({showBonusPackagePicker:false});
        } else {
            this.setState({showBonusPackagePicker:false});
        }
    }

    getBonusPackages(product) {
        const bonusPackages = (product.bonusPackages||[]);
        const ilist = [];

        for (let i in bonusPackages) {
            const pkg = campaign.getPackageInfo(bonusPackages[i]);
            if (pkg) {
                ilist.push(<tr key={i}>
                    <td className="hoverhighlight" onClick={this.onClickPackage.bind(this, pkg)}>
                        {pkg.name}
                    </td>
                </tr>);
            }
        }
        if (!ilist.length) {
            return null;
        }

        return <div  className="stdlist mv1">
            <table>
                <tbody>
                    {ilist}
                </tbody>
            </table>
        </div>
    }

    getPartialPurchase(product) {
        const summary = product.summary||{};
        const partialPrice = product.partialPrice||{};
        const list = [];

        for (let i in summary) {
            if (!excludeTypes[i]) {
                const pp = partialPrice[i]||{};
                list.push(<tr key={i}>
                    <td className="w-100">
                        {i}
                    </td>
                    <td className="mw3">
                        <PriceVal price={pp.group||0} onChange={this.setPartialPrice.bind(this, partialPrice, i, false)}/>
                    </td>
                    <td className="mw3">
                        <PriceVal price={pp.individual||0} onChange={this.setPartialPrice.bind(this, partialPrice, i, true)}/>
                    </td>
                </tr>);
            }
        }
        if (!list.length) {
            return null;
        }
        return <div>
            <div className="f2 bb titleborder titlecolor mb1 mt2">Partial Purchase Options</div>
            <div className="hk-well mt1">Enter prices if you would like to allow purchasing portions of the product.  Group price will allow purchase all of a single type.  Individual price will allow purchase of each instance of that type.  Dependent art will also be included.</div>
            <div  className="stdlist mv1">
                <table>
                    <tbody>
                        <tr className="b"><td>Type</td><td>Group</td><td>Individual</td></tr>
                        {list}
                    </tbody>
                </table>
            </div>
        </div>;
    }

    setPartialPrice(partialPriceOrig, type, individual, val) {
        const partialPrice = Object.assign({}, partialPriceOrig);
        if (!partialPrice[type]) {
            partialPrice[type]={};
        } else {
            partialPrice[type]=Object.assign({}, partialPrice[type]);
        }
        if (individual) {
            partialPrice[type].individual=val;
        } else {
            partialPrice[type].group=val;
        }
        this.setValue("partialPrice",partialPrice);
    }

    onClickPackage(pkg) {
        this.setState({showPackage:true, showPackagePkg:pkg})
    }

    onClosePackage() {
        this.setState({showPackage:false})
    }
}

const publishOptions={
    public:"Public",
    preview:"Coming Soon",
    discontinued:"Discontinued"
}
class DoPublish extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const product = Object.assign({}, campaign.getProductInfo(this.props.product));
            product.publishState = product.publishState||"public";
            this.setState({product})
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const product=this.state.product||{};

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Publish: {product.displayName}</DialogTitle>
            <DialogContent>
                <SelectVal helperText="Publish State" value={product.publishState} values={publishOptions} onClick={this.changePublishState.bind(this)} fullWidth/>
                <div className="mt2 hk-well">
                    <p>Publish state will determine visibility and what people are allowed to do with a product.</p>
                    <p className="mt1">Coming Soon and Discontinued products are not visible in the marketplace or available for purchase.</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.publishPackage.bind(this)} color="primary">
                    Publish
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Uploading Product...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    changePublishState(publishState) {
        const product=Object.assign({}, this.state.product);
        product.publishState = publishState;
        this.setState({product});
    }

    publishPackage() {
        const t=this;
        const product = Object.assign({}, this.state.product);

        const includedPackages = (product.includedPackages||[]);
        const included = {};

        if (includedPackages.length < 10) {
            for (let i in includedPackages) {
                const pkg = campaign.getPackageInfo(includedPackages[i]);
                if (pkg) {
                    getPkgEntries(pkg, included);
                } else {
                    displayMessage("You do not have access to an included package.  Make sure that you get package: "+includedPackages[i]);
                    return;
                }
            }
        }

        product.includedContent = included;

        campaign.updateCampaignContent("products",product);

        marketplace.publishProduct(product).then(function (){
            displayMessage("Product published");
            t.setState({loading:false});
            t.props.onClose();
        },function (err) {
            displayMessage("Error publishing product:"+err.message);
            t.setState({loading:false});
        })
        this.setState({loading:true})
    }
}

class UnlockKey extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.getFreeKeyInfo();
            this.setState({key:null})
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const availFreeKeys = (this.state.allowedFreeKeys||0)- (this.state.used_free_keys||0);

        return <Dialog
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Product Unlock Key</DialogTitle>
            <DialogContent>
                {this.state.allowedFreeKeys?(availFreeKeys?<div className="mb2">You can retrieve {availFreeKeys} more free unlock keys.</div>:<div className="mb2">No more free keys available.</div>):<div className="mb2">Checking on free keys...</div>}
                {this.state.key?<div>
                    Key has been generated: <ClipboardCopy text={this.state.key}>{this.state.key}</ClipboardCopy>
                </div>:null}
                <div className="hk-well">
                    An unlock key allows you to give a link that someone can use to purchase your product.  Unless it is a free key, you will be charged a marketplace fee when the key is claimed.
                    <div className="mt1">
                        <Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.newKey.bind(this)}>Get Key</Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Getting Key...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    async newKey() {
        const t=this;

        try {
            this.setState({loading:true});
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=getkey", JSON.stringify({
                product_id:t.props.product_id
            }))

            const response = JSON.parse(responseText);
            this.setState({key:response.key, loading:false});

            this.getFreeKeyInfo();
            displayMessage("Key generated");
        } catch (err) {
            displayMessage("Error: "+err.message);
            t.setState({loading:false});
        }
    }

    async getFreeKeyInfo() {
        const t=this;

        try {
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=getproductfreekey", JSON.stringify({
                product_id:t.props.product_id
            }))

            const response = JSON.parse(responseText);
            this.setState({used_free_keys:response.used_free_keys, allowedFreeKeys:response.allowedFreeKeys});
        } catch (err) {
            console.log("error getting free key info",err);
            displayMessage("Error: "+err.message, function (){
                t.props.onClose();
            });
        }
    }

}

class AddProductKey extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {loading:true };
    }

    componentDidMount() {
        if (this.props.open) {
            this.startLoading();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.startLoading();
        }
    }

    async startLoading() {
        const t=this;
        this.state= {loading:true };

        try {
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=checkkey", JSON.stringify({
                productkey:t.props.productkey,
                secret:t.props.secret
            }));
            const response = JSON.parse(responseText);
            const product_id = response.product_id;
            const product = await marketplace.loadProductInfo(product_id)

            t.setState({loading:false, product});
        } catch (err) {
            displayMessage("Key Error: "+err.message, function (){t.handleClose();});
        }
    }

    handleClose() {
        window.location.href = "/#home";
    };

    render() {
        if (!this.props.open)
            return null;

        if (this.state.loading) {
            return <Dialog open>
                <DialogContent>
                    Processing Key...
                </DialogContent>
            </Dialog>
        }
        const product=this.state.product||{};

        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Add Product</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <p>Do you want to install product <a onClick={this.showDetails.bind(this)}>{product.displayName}</a>?</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.addProduct.bind(this)} color="primary">
                    Add
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ProductDialog product={product} onClose={this.closeDetails.bind(this)} open={this.state.showDetails}/>
        </Dialog>;
    }

    showDetails() {
        this.setState({showDetails:true})
    }

    closeDetails() {
        this.setState({showDetails:false})
    }

    async addProduct() {
        const t=this;
        this.state= {loading:true };

        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=addkey",JSON.stringify({
                productkey:t.props.productkey,
                secret:t.props.secret
            }));
            displayMessage("Product added", function (){t.handleClose();});
        } catch (err) {
            displayMessage("Key Error: "+err.message, function (){t.setState({loading:false})});
            console.log("error", err)
        }
    }
}

class AddGift extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {loading:true };
    }

    componentDidMount() {
        if (this.props.open) {
            this.startLoading();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.startLoading();
        }
    }

    async startLoading() {
        const t=this;
        this.state= {loading:true };

        try {
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=checkgift", JSON.stringify({
                giftkey:t.props.giftkey
            }));
            const response = JSON.parse(responseText);
            const purchases = response.purchases;
            const productList = []

            for (let i in purchases) {
                const purchase = purchases[i];
                const product = await marketplace.loadProductInfo(purchase.product_id);
                productList.push({purchase, product});
            }

            t.setState({loading:false, productList});
        } catch (err) {
            displayMessage("Gift Error: "+err.message, function (){t.handleClose();});
        }
    }

    handleClose() {
        window.location.href = "/#home";
    };

    render() {
        if (!this.props.open)
            return null;

        if (this.state.loading) {
            return <Dialog open>
                <DialogContent>
                    Processing Gift...
                </DialogContent>
            </Dialog>
        }
        const productList=this.state.productList;
        const list = [];

        for (let i in productList) {
            const p=productList[i];
            list.push(<tr key={i}>
                <td><a onClick={this.showDetails.bind(this,p.product)}>{p.product.displayName}</a></td>
                <td className="tr">{p.purchase.subscription?(p.purchase.subscription_months+" months"):null}</td>
            </tr>)
        }

        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Gift</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    You have gift that includes:
                </div>
                <table className="w-100">
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.add.bind(this)} color="primary">
                    Accept
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ProductDialog product={this.state.showProduct} onClose={this.closeDetails.bind(this)} open={this.state.showDetails}/>
        </Dialog>;
    }

    showDetails(showProduct) {
        this.setState({showDetails:true, showProduct})
    }

    closeDetails() {
        this.setState({showDetails:false})
    }

    async add() {
        const t=this;
        this.state= {loading:true };

        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=addgift",JSON.stringify({
                giftkey:t.props.giftkey
            }));
            displayMessage("Gift added", function (){t.handleClose();});
        } catch (err) {
            displayMessage("Gift Error: "+err.message, function (){t.setState({loading:false})});
            console.log("error", err)
        }
    }
}

const maxShares = 6;
class RoyaltyShare extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({royaltyShare:this.props.royaltyShare});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const royaltyShare = this.state.royaltyShare||[];
        const list=[];

        for (let i=0; i<maxShares; i++) {
            const r = royaltyShare[i]||{};
            list.push(<tr key={i}>
                <td>{i+1}</td>
                <td><TextVal text={r.email||""} onChange={this.changeRoyalty.bind(this,i,"email")} fullWidth/></td>
                <td className="mw2"><TextVal text={r.percent||0} inputProps={{className:"tr"}} isNum onChange={this.changeRoyalty.bind(this,i,"percent")} fullWidth/></td>
            </tr>)
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.save.bind(this,false)}>Royalty Sharing</DialogTitle>
            <DialogContent>
                <table className="w-100">
                    <tbody>
                        <tr><td></td><td>Email</td><td className="mw2">Percent</td></tr>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.save.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.save.bind(this,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeRoyalty(i,prop, value) {
        const royaltyShare = (this.state.royaltyShare||[]).concat([]);
        royaltyShare[i]=Object.assign({},royaltyShare[i]||{});
        royaltyShare[i][prop]=value;
        this.setState({royaltyShare})
    }

    save(save) {
        if (save) {
            const royaltyShare = this.state.royaltyShare||[];
            let percent = 0;

            for (let i in royaltyShare) {
                const r = royaltyShare[i];
                if (r.percent && !r.email) {
                    displayMessage("Cannot assign a percentage without an email address");
                    return;
                }
                percent += (r.percent||0);
            }
            if (percent > 100) {
                displayMessage("Cannot assign more than 100 percent");
                return;
            }
            this.props.onClose(royaltyShare);
        } else {
            this.props.onClose();
        }
    }
}

class ProductDisplay extends React.Component {
    constructor(props) {
        super(props);

        this.state= {curThumb:-1, loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.product || !this.props.product || (prevProps.product.name != this.props.product.name)) && (this.state.curThumb>0)) {
            this.setState({curThumb:-1,expanded:null});
        }
    }

	render() {
        const product = this.props.product;

        if (!product) {
            return <div>Product not found</div>;
        }

        const subscription = product.purchaseType=="subscription";
        const summary = product.summary||{};
        const summaryList = [];
        const imgList = [];
        let showThumb = product.thumb;
        const bdiscount = this.props.readonly?0:computeDiscount(product);
        const markcamp = this.props.useCampaign?campaign:marketplace;
        const owned = !this.props.readonly && markcamp.isOwned(product.name);
        const cdiscount = marketplace.getCouponProductDiscount(product);
        const salePrice = (product.salePrice||0) - Math.trunc((product.salePrice||0)*cdiscount)/100;
        const monthlySalePrice = (product.monthlySalePrice||0) - Math.trunc((product.monthlySalePrice||0)*cdiscount)/100;
        const yearlySalePrice = (product.yearlySalePrice||0) - Math.trunc((product.yearlySalePrice||0)*cdiscount)/100;
        const discount = bdiscount * (100-cdiscount)/100;
        const isDollarProduct = dollarProducts.includes(product.name);
    
        for (let i in summary) {
            summaryList.push(<span key={i}>{summaryList.length?", ":""}{summary[i]} {i}</span>);
        }

        const altThumbs = product.altThumbs;
        if (altThumbs && altThumbs.length) {
            const curThumb = this.state.curThumb;
            if ((curThumb>=0) && (curThumb < altThumbs.length)) {
                showThumb = altThumbs[curThumb];
            }

            if (product.thumb) {
                imgList.push(<img key="def" src={product.thumb} className={(curThumb <0)?"w-16 pa--2":"w-16 pa--2 hoverundim"} onClick={this.setCurThumb.bind(this, -1)}/>);
            }

            for (let i in altThumbs) {
                imgList.push(<img key={i} src={altThumbs[i]} className={(curThumb == i)?"w-16 pa--2":"w-16 pa--2 hoverundim"} onClick={this.setCurThumb.bind(this,i)}/>);
            }
        }

        return <div>
            <div className="flex flex-wrap mb1 items-start">
                <div className="w5 flex-1 mh1 minw5 shadow-3 pa--3">
                    <img className="w-100" src={showThumb||"/noimage.png"}/>
                    {imgList.length?<div>{imgList}</div>:null}
                </div>
                <div className="ma1 flex-2 w5 minw55">
                    <div>
                        <div className="titlecolor titletext f1">{product.displayName}</div>
                        {product.publisher?<div className="ml2 mb1 f4 i">
                            {this.props.onClickPublisher?<a onClick={this.props.onClickPublisher}>{product.publisher}</a>:product.publisher}
                        </div>:null}
                        {product.shortDescription?<div className="f2 pv1 near-black">{product.shortDescription}</div>:null}
                        {isDollarProduct?null:subscription?<div>
                            <table className="f1 mb1 notetext">
                                <tbody>
                                    {product.monthlyPrice&&monthlySalePrice?<tr><td className="tr"><ProductPrice price={product.monthlyPrice} sale={monthlySalePrice}/></td><td className="w-100">/month</td></tr>:null}
                                    {product.yearlyPrice&&yearlySalePrice?<tr><td className="tr"><ProductPrice price={product.yearlyPrice} sale={yearlySalePrice}/></td><td className="w-100">/year</td></tr>:null}
                                </tbody>
                            </table>
                            {(owned && owned.renewable && owned.renews_yearly && monthlySalePrice && yearlySalePrice)?<Button className="ml2 mb1" onClick={this.setRenewal.bind(this, true, false)} variant="contained" color="primary">Switch To Monthly Renewal</Button>:null}
                            {(owned && owned.renewable && !owned.renews_yearly && monthlySalePrice && yearlySalePrice)?<Button className="ml2 mb1" onClick={this.setRenewal.bind(this, true, true)} variant="contained" color="primary">Switch To Yearly Renewal</Button>:null}
                            {(owned && !owned.renewable)?<Button className="ml2 mb1" onClick={this.setRenewal.bind(this, true, true)} variant="contained" color="primary">Enable Renewal</Button>:null}
                            {(owned && owned.renewable)?<Button className="ml2 mb1" onClick={this.setRenewal.bind(this, false, true)} variant="contained" color="primary">Cancel Renewal</Button>:null}
                        </div>:<div className="f1 mb1 notetext">
                            <ProductPrice price={product.listPrice} sale={salePrice} discount={discount}/>
                            {discount?<div className="mt1 f5">(additional discount for previous purchases)</div>:null}
                        </div>}
                        {(!product.publishState || product.publishState=="public" || isDollarProduct)?<div className="ph2 "><BuyButton product={product} readonly={this.props.readonly} noGift={this.props.noGift} useCampaign={this.props.useCampaign}/></div>:<div className="b f1">{publishOptions[product.publishState]}</div>}
                        {this.getAdminOptions()}
                    </div>
                    <div className="pt1 stdcontent">
                        {product.description?<Renderentry entry={{type:"html", html:product.description}}/>:null}
                        {shardSubscriptionList.includes(product.name)?<ShardSubscriptions/>:null}
                    </div>
                </div>
            </div>
            {subscription?<PackageList packageList={product.bonusPackages} label="Bonus packages included with subscription"/>:null}
            <div className="mv1 ba titleborder br2 pa1">
                <div className="titlecolor titletext f3 mb1">Product Details</div>
                <TextLabelEdit noMargin label="Published" text={product.publishDate && ((product.publishDate.toDate && product.publishDate.toDate()) ||(new Date(product.publishDate))).toLocaleDateString(undefined,dateOptions)}/>      
                <TextLabelEdit noMargin label="Category" text={product.type?product.type.join(", "):null}/>                
                <TextLabelEdit noMargin label="Theme" text={product.genre?product.genre.join(", "):null}/>                
                <TextLabelEdit noMargin label="Setting" text={product.setting?product.setting.join(", "):null}/>                
                <TextLabelEdit noMargin label="Storyline" text={product.storyline?product.storyline.join(", "):null}/>    
                <TextLabelEdit noMargin label="Ruleset" text={product.rulesets?product.rulesets.join(", "):null}/>    
                {product.startlevel?<div>
                    <TextLabelEdit label="Adventure level " text={product.startlevel} noDiv/>      
                    <TextLabelEdit label=" -" text={product.endlevel} noDiv/>
                </div>:null}      
                {summaryList.length?<TextLabelEdit noMargin label="Includes" text={summaryList}/>:null}
            </div>
            {subscription?<ProductList productList={getUpgradeSubscriptions(product)} label="Upgraded versions of this subscription…" onClick={this.onClickProduct.bind(this)} readonly={this.props.readonly}/>:null}
            <ProductList productList={getRecommendedProducts(product)} label="You may also like…" onClick={this.onClickProduct.bind(this)} readonly={this.props.readonly}/>
            {this.getPartialPurchase(product)}
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Updating...
                </DialogContent>
            </Dialog>
        </div>;
    }

    onClickProduct(product) {
        if (!this.props.readonly) {
            window.location.href = "/marketplace#product?id="+product.name;
        }
    }

    getAdminOptions() {
        if (!marketplace.isAdmin()) {
            return null;
        }
        return <div className="mt2">
            <Button className="mr1" color="primary" variant="outlined" size="small" onClick={this.showFeatureSettings.bind(this,true)}>Featured Settings</Button>
            <Button className="mr1" color="primary" variant="outlined" size="small" onClick={this.showAssignProduct.bind(this)}>Assign</Button>
            <Button className="mr1" color="primary" variant="outlined" size="small" onClick={this.getPackages.bind(this)}>Get Packages</Button>
            <Button className="mr1" color="primary" variant="outlined" size="small" onClick={this.showGiftProduct.bind(this,true)}>Gift Product</Button>

            <TextBasicEdit show={this.state.showAssignProduct} onChange={this.doAssignProduct.bind(this)} label="Assign to Email" info="Enter the email address of the account to assign the product"/>
            <FeaturedProductSettings open={this.state.showFeaturedSettings} onClose={this.showFeatureSettings.bind(this,false)} product={this.props.product.name}/>
            <GiftProduct open={this.state.showGiftProduct} onClose={this.showGiftProduct.bind(this,false)} product={this.props.product}/>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Processing...
                </DialogContent>
            </Dialog>
        </div>
    }

    showGiftProduct(showGiftProduct) {
        this.setState({showGiftProduct});
    }

    showAssignProduct() {
        this.setState({showAssignProduct:true});
    }

    async doAssignProduct(email) {
        this.setState({showAssignProduct:false});
        if (email) {
            this.setState({loading:true});
            try {
                await httpAuthRequestWithRetry("POST", "/search?cmd=assignproduct", JSON.stringify({productId:this.props.product.name,email}));
                displayMessage("Product assigned");
            } catch (error) {
                displayMessage("Could not assign the product: "+error.message);
            }
    
            this.setState({loading:false});
        }
    }

    async getPackages() {
        this.setState({loading:true});
        try {
            const product = this.props.product;
            for (let i in product.includedPackages) {
                await httpAuthRequestWithRetry("POST", "/search?cmd=addsharedpackage", JSON.stringify({pkg:product.includedPackages[i],key:1}));
            }
            for (let i in product.bonusPackages) {
                await httpAuthRequestWithRetry("POST", "/search?cmd=addsharedpackage", JSON.stringify({pkg:product.bonusPackages[i],key:1}));
            }
            displayMessage("Packages added");
        } catch (error) {
            displayMessage("Could not get the packages: "+error.message);
        }

        this.setState({loading:false});
    }

    showFeatureSettings(showFeaturedSettings) {
        this.setState({showFeaturedSettings});
    }

    async setRenewal(renewable, renews_yearly) {
        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=setsubscription", JSON.stringify({
                renewable,
                renews_yearly,
                product_id:this.props.product.name
            }));
        } catch (error) {
            displayMessage("Error updating subscription: "+error.message);
        }

        this.setState({loading:false});
    }

    setCurThumb(curThumb) {
        this.setState({curThumb});
    }

    getPartialPurchase(product) {
        if (!product.includedContent || this.props.readonly) {
            return null;
        }
        const {expanded}=this.state;
        const partialPrice = product.partialPrice||{};
        const list = [];
        const keys = Object.keys(product.includedContent).sort();
        const markcamp = this.props.useCampaign?campaign:marketplace;

        for (let k in keys) {
            const i = keys[k];
            const pp = partialPrice[i]||{};
            const ilist = [];
            const included = product.includedContent[i];
            const e = (expanded||{})[i]??(pp.individual||pp.group)?true:false;
            const plist = [];

            for (let x in included) {
                plist.push({id:x, displayName:included[x]});
            }
            plist.sort(function (a,b) {return (a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())) });
            if (pp.individual) {
                for (let x in plist) {
                    const p = plist[x];
                    const incart = isInCart(product.name, i, p.id);
                    const owned = markcamp.isOwned(product.name, i, p.id);
                    ilist.push(<div className="w-50 minw5 flex-auto pa1" key={p.id}>
                        <Button className="mr1" disabled={!!(incart||owned)} color="primary" variant="outlined" size="small" onClick={addToCart.bind(null, product.name, false, i, p.id, p.displayName)}><span className="mr1 fas fa-shopping-cart"/>{owned?"Owned":incart?"In Cart":("$"+pp.individual.toFixed(2))}</Button>
                        {p.displayName}
                    </div>);
                }
            } else if (!["Art","Books","Encounters","Pins","Maps"].includes(i)) {
                for (let x in plist) {
                    const p = plist[x];
                    ilist.push(<div className="w-50 minw5 mw55 flex-auto pa1" key={p.id}>
                        <li>{p.displayName}</li>
                    </div>);
                }
            }

            if (ilist.length) {
                const incart = isInCart(product.name, i, null);
                const owned = markcamp.isOwned(product.name, i, null);
                list.push(<div key={i}>
                    <h1>{i} ({plist.length}) &nbsp;&nbsp;&nbsp;<span onClick={this.toggleExpanded.bind(this,i, !e)} className={e?"far fa-minus-square":"far fa-plus-square"}></span></h1>
                    {pp.group?<div>Purchase all <span className="ttl">{plist.length} {i} for ${pp.group.toFixed(2)}</span>.
                        <Button className="ml2" disabled={!!(incart||owned)} color="primary" variant="outlined" size="small" onClick={addToCart.bind(null, product.name, false, i, null,null)}>{owned?"Owned":incart?"In Cart":"Add to Cart"}</Button>
                    </div>:null}
                    {e?<div className="mv1">
                        {pp.individual?<div>Purchase individually.</div>:null}
                        <div className="flex flex-wrap">{ilist}</div>
                    </div>:null}
                </div>)
            }
        }
        if (list.length) {
            return <div className="stdcontent">
                {product.partialPrice?<h1>Additional Purchase Options</h1>:null}
                {list}
            </div>
        }
        return null;
    }

    toggleExpanded(i, e ){
        const expanded=Object.assign({},this.state.expanded||{});
        expanded[i]=e;
        this.setState({expanded});
    }
}

const minRecommendedDefault = 6;
const msInDay = 1000*24*60*60;
function getRecommendedProducts(product) {
    if (!product) {
        return null;
    }

    let minRecommended = minRecommendedDefault;
    const list = [];
    const products = marketplace.products||{};
    if (product.recommendedProducts) {
        for (let i in product.recommendedProducts) {
            const id = product.recommendedProducts[i];
            const p = products[id];
            if (p && (p.publishState == "public") && !!marketplace.isOwned(id)) {
                list.push({weight:-100, product:id});
            }
        }
        minRecommended=Math.max(product.recommendedProducts.length,minRecommended);
    }
    const now = Date.now();

    for (let i in products) {
        const p = products[i];
        if ((product.name!=i) && (p.publishState == "public") && (p.userId==product.userId) && !marketplace.isOwned(i) && !list.find(function (a){return a.product==i})) {
            
            let weight = 0;
            let sum=0;
            if (p.publishDate) {
                const published = (((p.publishDate.toDate && p.publishDate.toDate()) ||(new Date(p.publishDate)))).getTime();
                weight += Math.log((now-published)/msInDay+1)/10;
            }
            if (marketplace.getProductFeaturedWeight(i)) {
                //weight -= 1;
            }
            sum += matchWeightSum(product.type,p.type);
            sum += matchWeightSum(product.genre,p.genre);
            sum += matchWeightSum(product.setting,p.setting);
            sum += matchWeightSum(product.storyline,p.storyline);
            weight += Math.sqrt(sum);
            weight -= (getPopularity(p, 0.2)/20);
            if (!p.salePrice && !p.monthlySalePrice && !p.yearlySalePrice) {
                weight+=3;
            }
            list.push({weight, product:p.name});
        }
    }
    list.sort(function (a,b){return a.weight-b.weight});
    if (list.length > minRecommended) {
        list.splice(minRecommended,list.length-minRecommended)
    }
    return list.map(function (a){return a.product});
}

function matchWeightSum(base, compare) {
    if (!base) {
        return 0;
    }
    
    let sum = 0;

    if (Array.isArray(base)) {
        for (let i in base) {
            if (!compare || !compare.includes(base[i])){
                sum++;
            }
        }
    } else {
        if (base != compare) {
            sum++;
        }
    }
    return sum;
}

function getUpgradeSubscriptions(product) {
    const products = marketplace.products||{};
    const list = [];

    for (let i in products) {
        const p = products[i];
        if ((p.purchaseType=="subscription") && p.subscriptionUpgrade && p.subscriptionUpgrade.includes(product.name)) {
            list.push(i);
        }
    }
    return list;
}

function computeDiscount(product) {
    const owned = product && marketplace.isOwned(product.name);
    if (product.purchaseType=="subscription" || owned) {
        return 0;
    }

    let discount = 0;
    let price = product.salePrice||0;
    const ownedAmount = (marketplace.owned[product.name]||{}).amount||0;
    let previousCredit = Math.min(ownedAmount,price);
    const purchasedPackages = marketplace.purchasedPackages||{};
    const packageCredits = (product.packageCredits||{});

    for (let i in purchasedPackages) {
        if (purchasedPackages[i]) {
            discount += (packageCredits[i]||0);
        }
    }
    if (discount > 100) {
        discount=100;
    }

    return Math.trunc((price-previousCredit)*discount)/100+previousCredit;
}

class PackageList extends React.Component {
    constructor(props) {
        super(props);

        this.loadPackageInfo();
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.packageList != this.props.packageList) {
            this.loadPackageInfo();
        }
    }

    async loadPackageInfo() {
        if (this.props.packageList) {
            const packageMap = await marketplace.getPackages(this.props.packageList);
            this.setState({packageMap})
        }
    }

    render () {
        const packageMap = this.state.packageMap||{};
        const packageList = this.props.packageList;
        const list=[];

        for (let i in packageList) {
            const pkg = packageMap[packageList[i]];
            if (pkg && pkg.thumbnail) {
                list.push(<div className="dib pa1 hoverhighlight tc v-top" style={{width:120}} key={pkg.name} onClick={this.showPackage.bind(this, pkg)}>
                    <img width="100%" src={pkg.thumbnail}/>
                    <div className="f4 titlecolor">{pkg.name}</div>
                </div>);
            }
        }
        if (!list.length) {
            return null;
        }
        return <div className="pv1">
            <div className="f3 titlecolor titletext">{this.props.label}</div>
            {list}
            <PackageDialog open={this.state.showPackage} pkg={this.state.showPackagePkg} onClose={this.hideShowPackage.bind(this)} viewOnly/>
        </div>
    }

    showPackage(showPackagePkg) {
        this.setState({showPackage:true, showPackagePkg});
    }

    hideShowPackage() {
        this.setState({showPackage:false});
    }
}

class ProductList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    render () {
        const {productRender} = require('./marketplacesearch.jsx');
        const products = marketplace.products||{};
        const productList = this.props.productList;
        const list=[];

        for (let i in productList) {
            const p = products[productList[i]];
            if (p) {
                list.push(<div className="shadow-3 ma1 pa1 hoverhighlight dib relative flex flex-column w-25 minw45" style={{width:180}} key={p.name}>
                    <div className="dib relative w-100 tc" onClick={this.showProduct.bind(this, p)}>
                        <img width="100%" src={p.thumb || "/noimage.png"}/>
                    </div>
                    {productRender(p, this.showProduct.bind(this, p), this.props.readonly)}
                </div>);
            }
        }
        if (!list.length) {
            return null;
        }
        return <div className="pv1">
            <div className="f3 titlecolor titletext">{this.props.label}</div>
            <div className="flex flex-wrap justify-left w-100">
                {list}
            </div>
        </div>
    }

    showProduct(showProduct) {
        this.props.onClick(showProduct);
    }
}

class ProductDetailsEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidMount() {
        const t=this;
        marketplace.load().then(function (){t.setState({loadedinfo:true})});
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setProductState();
        }
    }

    setProductState() {
        const product =campaign.getProductInfo(this.props.product);
        this.setState({loading:false, product:product, descriptionEntry:{type:"html", html:product.description||""}});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const product = this.state.product||{};
        const {types,settings, storylines,rulesets} = marketplace.getAllVals();

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Product Details</DialogTitle>
            <DialogContent>
                <div className="stdcontent f4">
                    <TextVal fullWidth helperText="Product Name" text={product.displayName||""} onChange={this.onChangeVal.bind(this,"displayName")}/>                
                    <TextVal fullWidth helperText="Publisher" text={product.publisher||""} onChange={this.onChangeVal.bind(this,"publisher")}/>                
                    <TextVal fullWidth helperText="Summary" text={product.shortDescription} onChange={this.onChangeVal.bind(this,"shortDescription")} multiline/>                
                    <SelectMultiTextVal freeSolo value={product.type} values={types} onChange={this.onChangeVal.bind(this,"type")} fullWidth helperText="Category"/>
                    <SelectMultiTextVal value={product.genre} values={packageGenres} onChange={this.onChangeVal.bind(this,"genre")} fullWidth helperText="Theme"/>
                    <SelectMultiTextVal freeSolo value={product.setting} values={settings} onChange={this.onChangeVal.bind(this,"setting")} fullWidth helperText="Setting"/>
                    <SelectMultiTextVal freeSolo value={product.storyline} values={storylines} onChange={this.onChangeVal.bind(this,"storyline")} fullWidth helperText="Storyline"/>
                    <SelectMultiTextVal freeSolo value={product.rulesets} values={rulesets} onChange={this.onChangeVal.bind(this,"rulesets")} fullWidth helperText="Rulesets"/>
                    <div>
                        <TextLabelEdit label="Adventure level " text={product.startlevel} editable onChange={this.onChangeVal.bind(this,"startlevel")} values={levelVals} noDiv/>      
                        {product.startlevel?
                            <TextLabelEdit label=" - " text={product.endlevel} editable onChange={this.onChangeVal.bind(this,"endlevel")} values={levelVals} noDiv/>
                        :null
                        }
                    </div>
                    <div className="notetext f3 bb">Description</div>
                    <EntityEditor onChange={this.changeDescription.bind(this)} entry={this.state.descriptionEntry}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.save.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeDescription(nd) {
        const product = Object.assign({}, this.state.product);
        product.description = nd && nd.html;
        this.setState({product, descriptionEntry:nd})
    }

    onChangeVal(field, val) {
        const product = Object.assign({}, this.state.product);
        product[field] = val;
        this.setState({product})
    }

    save() {
        campaign.updateCampaignContent("products",this.state.product);
        this.props.onClose();
    }
}


class PickImages extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const product = this.props.product||{};
        const altThumbs = product.altThumbs||[];
        const list=[];

        if (product.thumb) {
            list.push(<tr key="default">
                <td>
                    <img width="75px" height="75px" src={product.thumb}/>
                </td>
                <td className="v-mid w-100 pa1 f3">
                    Default
                </td>
            </tr>);
        }

        if (product.socialthumb) {
            list.push(<tr key="socdefault">
                <td>
                    <img width="150px" height="75px" src={product.socialthumb}/>
                </td>
                <td className="v-mid w-100 pa1 f3">
                    Social image
                </td>
            </tr>);
        }

        for (let i in altThumbs) {
            list.push(<tr key={i}>
                <td>
                    <img width="75px" height="75px" src={altThumbs[i]}/> 
                </td>
                <td className="v-mid w-100 pa1">
                    <Button onClick={this.setDefault.bind(this, i)} color="primary" size="small" variant="outlined">
                        Set as Default
                    </Button>
                    <span className="fas fa-arrow-up pa1 hoverhighlight" onClick={this.shiftPos.bind(this, i, -1)}/>
                    <DeleteWithConfirm name="image" onClick={this.onDeleteImage.bind(this,i)}/>
                    <span className="fas fa-arrow-down pa1 hoverhighlight" onClick={this.shiftPos.bind(this, i, 1)}/>
                </td>
            </tr>);
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Product Images</DialogTitle>
            <DialogContent>
                <div className="hk-well mb1">Product images will be displayed in a 512x512 square format.  Social images will formatted to 1024x512 2:1 format.</div>
                <table className="w-100">
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <input
                    accept="image/*"
                    className="dn"
                    id={"image-file-upload-s"+product.name}
                    type="file"
                    onChange={this.selectSocialFile.bind(this)}
                />
                <label htmlFor={"image-file-upload-s"+product.name}>
                    <Button color="primary" component="span">
                        Upload Social Image
                    </Button>
                </label>
                <input
                    accept="image/*"
                    className="dn"
                    id={"image-file-upload"+product.name}
                    type="file"
                    multiple
                    onChange={this.selectFile.bind(this)}
                />
                <label htmlFor={"image-file-upload"+product.name}>
                    <Button color="primary" component="span">
                        Upload Images
                    </Button>
                </label>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading Images...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    setDefault(i) {
        const newProd = Object.assign({}, this.props.product);
        newProd.altThumbs = (newProd.altThumbs||[]).concat([]);
        const save = newProd.altThumbs[i];
        if (newProd.thumb) {
            newProd.altThumbs[i]=newProd.thumb;
        } else {
            newProd.altThumbs.splice(i,1);
        }
        newProd.thumb=save;
        campaign.updateCampaignContent("products",newProd);
    }

    shiftPos(i, direction) {
        const newProd = Object.assign({}, this.props.product);
        const newPos = Number(i)+Number(direction);
        if (newPos <0 || newPos >= newProd.altThumbs.length){
            return;
        }
        newProd.altThumbs = (newProd.altThumbs||[]).concat([]);

        const save = newProd.altThumbs[i];
        newProd.altThumbs[i]=newProd.altThumbs[newPos];
        newProd.altThumbs[newPos]=save;

        campaign.updateCampaignContent("products",newProd);
    }

    onDeleteImage(i) {
        const newProd = Object.assign({}, this.props.product);
        newProd.altThumbs = (newProd.altThumbs||[]).concat([]);
        newProd.altThumbs.splice(i,1);

        campaign.updateCampaignContent("products",newProd);
    }

    async selectFile(e) {
        const files = e.target.files;
        if (files.length > 0) {
            // upload files
            try {
                const product= this.props.product;
                const userId = campaign.currentUser.uid;
                const storage = getStorage(firebase);

                this.setState({loading:true});

                for (let i=0; i< files.length; i++) {
                    const file = files[i];
                    const path = "users/"+userId+"/MyProducts/"+product.name+"/"+campaign.newUid();

                    const imageInfo = await resizeAndCropImage(file, thumbnailSize,thumbnailSize)
                    const blob=imageInfo.blob;
                    const fileRefThumb = ref(storage, path+".png");

                    await uploadBytes(fileRefThumb, blob,{contentType:"image/png",cacheControl: "public,max-age=4838400"});
                    const downloadThumbURL = getDirectDownloadUrl(path+".png");
                    const curProd = campaign.getProductInfo(product.name);
                    if (curProd) {
                        const newProd = Object.assign({}, curProd);
                        newProd.altThumbs = (newProd.altThumbs||[]).concat([downloadThumbURL]);
                        campaign.updateCampaignContent("products",newProd);
                    }

                    if (i==0 && !product.socialthumb) {
                        const path = "users/"+userId+"/MyProducts/"+product.name+"/"+campaign.newUid();

                        const imageInfo = await resizeAndCropImage(file, 1024,512);
                        const blob=imageInfo.blob;
                        const fileRefThumb = ref(storage,path+".png");
    
                        await uploadBytes(fileRefThumb, blob,{contentType:"image/png",cacheControl: "public,max-age=4838400"});
                        const downloadThumbURL = getDirectDownloadUrl(path+".png");
                        const curProd = campaign.getProductInfo(product.name);
                        if (curProd) {
                            const newProd = Object.assign({}, curProd);
                            newProd.socialthumb = downloadThumbURL;
                            campaign.updateCampaignContent("products",newProd);
                        }
                    }
                }
                this.setState({loading:false});
            } catch (err) {
                this.errorSelectingFile("Error getting product images information", err);
            }
        }
    }

    async selectSocialFile(e) {
        if (e.target.files.length ==1) {
            // upload files
            try {
                const product= this.props.product;
                const userId = campaign.currentUser.uid;
                const storage = getStorage(firebase);

                this.setState({loading:true});

                const file = e.target.files[0];
                const path = "users/"+userId+"/MyProducts/"+product.name+"/"+campaign.newUid();

                const imageInfo = await resizeAndCropImage(file, 1024,512);
                const blob=imageInfo.blob;
                const fileRefThumb = ref(storage,path+".png");

                await uploadBytes(fileRefThumb,blob, {contentType:"image/png",cacheControl: "public,max-age=4838400"});
                const downloadThumbURL = getDirectDownloadUrl(path+".png");
                const curProd = campaign.getProductInfo(product.name);
                if (curProd) {
                    const newProd = Object.assign({}, curProd);
                    newProd.socialthumb = downloadThumbURL;
                    campaign.updateCampaignContent("products",newProd);
                }
                this.setState({loading:false});
            } catch(err) {
                this.errorSelectingFile("Error uploading social file", err);
            };
        }
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+"\nError:"+err);
        console.log(msg, err);
        this.setState({loading:false});
    }
}

class ConfigurePublisherInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidMount() {
        if (this.props.open) {
            load();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.load();
        }
    }    

    load() {
        const t=this;
        this.setState({loading:true})
        campaign.getPublisherInfo(this.props.userId).then(function (publisherInfo) {
            t.setState({publisherInfo, loading:false});
        },function(err) {
            displayMessage("Error reading publisher information: "+err.message);
            t.props.onClose();
        });
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const publisherInfo = this.state.publisherInfo||{};

        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Publisher Information</DialogTitle>
            <DialogContent>
                <div className="flex flex-wrap stdcontent">
                    {publisherInfo.image?<div><img className="pr2 ba b--light-gray" src={publisherInfo.image} height="200px"/></div>:<div style={{height:"200px", width:"400px"}}>No Image Selected</div>}
                    <div className="flex-auto mw7 minw6 ph2"><EntityEditor noSearch onChange={this.setProp.bind(this,"description")} entry={publisherInfo.description} placeholder="Description"/></div>
                </div>
                <div className="hk-well mv2">
                    Publisher logo should ideally be 1024x512. &nbsp;
                    <input
                        accept="image/*"
                        className="dn"
                        id="publisherlogo"
                        type="file"
                        onChange={this.selectFile.bind(this)}
                    />
                    <label htmlFor="publisherlogo">
                        <Button color="primary" variant="outlined" component="span">
                            Upload Logo
                        </Button>
                    </label>
                </div>
                <div>
                    <CheckVal value={publisherInfo.enabled||false} onChange={this.setProp.bind(this,"enabled")} label="Enabled"/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.save.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading ...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    save() {
        const t=this;
        this.setState({loading:true});
        campaign.setPublisherInfo(this.props.userId, this.state.publisherInfo||{}).then(function () {
            t.props.onClose();
        }, function (err) {
            t.errorSelectingFile("Could not save product info: ", err);
        });

    }

    setProp(name, value) {
        const publisherInfo = Object.assign({}, this.state.publisherInfo||{});
        publisherInfo[name]=value;
        this.setState({publisherInfo});
    }

    async selectFile(e) {
        if (e.target.files.length == 1) {
            try {
                // upload files
                const storage = getStorage(firebase);

                this.setState({loading:true});

                const file = e.target.files[0];
                const path = "users/"+campaign.userId+"/publisher/logo_"+campaign.newUid();

                const imageInfo = await resizeAndCropImage(file, 1024,512);
                const blob=imageInfo.blob;
                const fileRefThumb = ref(storage, path+".png");

                await uploadBytes(fileRefThumb, blob, {contentType:"image/png",cacheControl: "public,max-age=4838400"});
                const downloadThumbURL = getDirectDownloadUrl(path+".png");
                this.setProp("image", downloadThumbURL);
                this.setState({loading:false});
            } catch(err){
                this.errorSelectingFile("Error getting thumbnail file information", err);
            };
        }
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+"\nError:"+err);
        console.log(msg, err);
        this.setState({loading:false});
    }
}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const levelVals = [
    {name:"none", value:0},
    {name:"1", value:1},
    {name:"2", value:2},
    {name:"3", value:3},
    {name:"4", value:4},
    {name:"5", value:5},
    {name:"6", value:6},
    {name:"7", value:7},
    {name:"8", value:8},
    {name:"9", value:9},
    {name:"10", value:10},
    {name:"11", value:11},
    {name:"12", value:12},
    {name:"13", value:13},
    {name:"14", value:14},
    {name:"15", value:15},
    {name:"16", value:16},
    {name:"17", value:17},
    {name:"18", value:18},
    {name:"19", value:19},
    {name:"20", value:20},
];

function productRender(product) {
    const dirty = isDirtyProduct(product);
    return <div>
        <div className="notetext titlecolor f3 truncate tc">{product.displayName}</div>
        {product.purchaseType=="subscription"?<div className="f2 mb1 notetext tc">
            {product.monthlyPrice&&product.monthlySalePrice?<div><ProductPrice price={product.monthlyPrice} sale={product.monthlySalePrice}/>/month</div>:null}
            {!product.monthlySalePrice&&product.yearlyPrice&&product.yearlySalePrice?<div><ProductPrice price={product.yearlyPrice} sale={product.yearlySalePrice}/>/year</div>:null}
        </div>:<div className="f2 mb1 notetext tc">
            <ProductPrice price={product.listPrice} sale={product.salePrice}/>
        </div>}
        <div className="notetext i f6 red truncate tc">
            {!dirty&&(product.publishState=="public")?null:product.publishState||"unpublished"}
            {dirty?" / Needs publishing":null}</div>
    </div>;
}

function isDirtyProduct(product) {
    if (!marketplace.loaded) {
        return false;
    }
    const pp = (marketplace.products||{})[product.name];

    const dirty = !areSameDeepIgnore(product, pp, ["edited", "timestamp", "publishDate","lastPublished","userId","royaltyShare", "summary"]);
    //console.log("dirty", dirty, product,pp);
    return dirty;
}



class ProductsPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={selected:props.selected||{},loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadProducts();
            this.setState({selected:this.props.selected||{}});
        }
    }    

    loadProducts() {
        const t=this;
        this.setState({loading:true});
        marketplace.load().then(function () {
            const products = marketplace.products;
            const userId = campaign.userId;
            const productsList = [];
            for (let i in products) {
                const p = products[i];
                if ((!t.props.selectSubscriptions || (p.purchaseType=="subscription")) &&
                    (!t.props.selectOwned || (p.userId == userId))
                ) {
                    productsList.push(products[i]);
                }
            }
            if (t.props.dateSort) {
                productsList.sort(function (a,b){
                    const pda = a.publishDate?((a.publishDate.toDate && a.publishDate.toDate()) ||(new Date(a.publishDate))).getTime():0;
                    const pdb = b.publishDate?((b.publishDate.toDate && b.publishDate.toDate()) ||(new Date(b.publishDate))).getTime():0;
            
                    if (pda != pdb) {
                        return pdb-pda;
                    }
                    return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
                });
            } else {
                productsList.sort(function (a, b) {return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())});
            }
            t.setState({loading:false, productsList});
        },function (error) {
            t.setState({loading:false});
            displayMessage("Could not load products.  "+error.message);
            t.props.onClose();
        });
    }

    render () {
        if (!this.props.open) {
            return null;
        }
        const productsList = this.state.productsList||[];

        return <Dialog
            open
            maxWidth="md"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Products</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={productsList} 
                    render={productRender} 
                    filters={productPickFilters} 
                    onClick={this.clickProduct.bind(this)} 
                    select="list"
                    selected={this.state.selected}
                    single={this.props.single}
                    onSelectedChange={this.onSelectedChange.bind(this)} 
                    showThumbnails
                    border
                    selectAll
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ProductDialog open={this.state.showProduct} product={this.state.showProductInfo} onClose={this.onCloseProduct.bind(this)}/>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Loading Products...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    onCloseProduct() {
        this.setState({showProduct:false});
    }

    clickProduct(id) {
        const showProductInfo = marketplace.getProductInfo(id);
        this.setState({showProduct:true, showProductInfo})
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    onClose(save) {
        this.props.onClose(save?this.state.selected:null);
    }
}

class CouponsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state={loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadCoupons();
        }
    }    

    async loadCoupons() {
        const t=this;
        this.setState({loading:true});

        try {
            const responseText = await httpAuthRequestWithRetry("GET", "/search?cmd=coupons", null);
            const coupons = JSON.parse(responseText);
            this.setState({loading:false, coupons});
        } catch (err) {
            console.log("error loading coupons", err);
            displayMessage("Error loading coupons", function () {t.props.onClose()});
            this.setState({loading:false});
        }
    }

    render () {
        if (!this.props.open) {
            return null;
        }
        const coupons = this.state.coupons||[];
        const list = [];
        for (let i in coupons) {
            const c = coupons[i];
            list.push(<tr key={c.coupon_id}>
                <td className="tl hoverhighlight" onClick={this.showCoupon.bind(this,c)}>{c.name}</td>
                <td className="tc hoverhighlight" onClick={this.showCoupon.bind(this,c)}>{c.discount}%</td>
                <td className="tc hoverhighlight" onClick={this.showCoupon.bind(this,c)}><span className="nowrap">{(new Date(c.end_date)).toLocaleDateString()}</span></td>
                <td className="tc hoverhighlight" onClick={this.showCoupon.bind(this,c)}>{c.uses||0}</td>
                <td className="tc w1"><DeleteWithConfirm name={c.name} className="hoverhighlight pa1" onClick={this.deleteCoupon.bind(this,c)}/></td>
            </tr>);
        }

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Coupons</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <table>
                        <tbody>
                            <tr><th className="tl">Name</th><th>Discount</th><th>Ends</th><th>Uses</th><th></th></tr>
                            {list}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.newCoupon.bind(this)} color="primary">
                    New Coupon
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
            <EditCoupon open={this.state.showCoupon} coupon={this.state.selectedCoupon} onClose={this.closeShowCoupon.bind(this)}/>
        </Dialog>;
    }

    newCoupon(){
        this.setState({showCoupon:true, selectedCoupon:null});
    }

    async deleteCoupon(c){
        const coupon = {coupon_id:c.coupon_id, delete:true};

        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=updatecoupon", JSON.stringify(coupon));
        } catch (err) {
            console.log("error deleting coupon", err);
            displayMessage("Error deleting coupon: "+err.message);
        }
        this.loadCoupons();
    }

    showCoupon(selectedCoupon){
        this.setState({showCoupon:true, selectedCoupon});
    }

    closeShowCoupon() {
        this.setState({showCoupon:false});
        this.loadCoupons();
    }

}

const allBonusRequirements = ["All", "Any", "None"];
const limitedBonusRequirements = ["Any", "None"];
class EditCoupon extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false,coupon:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const t=this;
            const n = getDateStrFromDate(new Date(Date.now()+7*24*60*60*1000));

            const coupon = this.props.coupon||{coupon_id:campaign.newUid(),code:campaign.newUid().substr(0,6),end_date:n, discount:20};
            this.setState({coupon, loading:true})
            marketplace.load().then(function (){
                t.setState({loading:false});
            });
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const products = marketplace.products||{};
        const coupon = this.state.coupon||{};
        const productList = (coupon.products||"").split(",");
        const selected = {};
        const selectedBonus = {};
        const plist = [];
        const bonusProduct = products[coupon.bonus_product];
        let foundProduct;
        for (let i of productList) {
            if (products[i]) {
                foundProduct=true;
                plist.push(products[i].displayName)
                selected[i]=true;
            }
        }
        const page={code:coupon.code};
        if (bonusProduct) {
            selectedBonus[coupon.bonus_product]=1;
        }

        return <Dialog
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Coupon</DialogTitle>
            <DialogContent>
                <div className="hk-well mb1">
                    Coupon codes will give users a percentage discount on any products listed.  The code can be typed in by the user or sent to them with a URL.  The coupon will be accepted until the end date or a limited number of uses.
                </div>
                {coupon.image?<div><img className="pr2 ba b--light-gray" src={coupon.image} height="200px"/></div>:null}
                <TextVal className="mb1" text={coupon.name||""} fullWidth onChange={this.changeProp.bind(this, "name")} helperText="Name"/>
                <TextVal className="mb1" text={coupon.code||""} filter={/\W/} fullWidth onChange={this.changeProp.bind(this, "code")} helperText="Code (case insensitive)"/>
                <SelectVal className="mb1" isNum value={coupon.discount||""} values={marketplace.isAdmin()?[5,10,15,20,25,30,35,40,45,50,60,70,75,80,90,95,100]:[5,10,15,20,25,30,35,40,45,50]} fullWidth onClick={this.changeProp.bind(this, "discount")} helperText="% Discount"/>
                <DateVal className="mb1" fullWidth date={getDateStrFromDate(new Date(coupon.end_date))} onChange={this.changeProp.bind(this, "end_date")} helperText="end date"/>
                <TextVal className="mb1" isNum text={coupon.max_uses||""} fullWidth placeholder="unlimited" onChange={this.changeProp.bind(this, "max_uses")} helperText="Max Uses (0=unlimited)"/>
                <TextVal className="mb1" multiline text={coupon.description||""} fullWidth onChange={this.changeProp.bind(this, "description")} helperText="Description"/>
                <div className="mv1">
                    <b>Discounted Products: </b> {coupon.products?plist.join(", "):"all of your published products"}
                </div>
                <div className="hk-well mv2">
                    Coupon image should ideally be 1024x512. The image will be shown in the marketplace when the user clicks on a URL for the coupon.&nbsp;
                    <div>
                        <input
                            accept="image/*"
                            className="dn"
                            id="couponimage"
                            type="file"
                            onChange={this.selectFile.bind(this)}
                        />
                        <label htmlFor="couponimage">
                            <Button color="primary" variant="outlined" component="span">
                                Upload Coupon Image
                            </Button>
                        </label>
                    </div>
                </div>
                <div className="hk-well">
                    Bonus Product: grant this product for free based on the purchase requirement. 
                    Trial subscriptions will only be counted if the user has not had the subscription for at least 28 days.
                    <div className="mv1">
                        <Button onClick={this.showPickBonus.bind(this)} color="primary" variant="outlined">
                            Pick Bonus Product
                        </Button> {bonusProduct?.displayName||""}
                    </div>
                    {bonusProduct?<div className="mb1">
                        <SelectVal helperText="Purchase Requirement" fullWidth value={coupon.bonus_requirement||"Any"} values={foundProduct?allBonusRequirements:limitedBonusRequirements} onClick={this.changeProp.bind(this,"bonus_requirement")}/>
                        {coupon.bonus_requirement!="None"?<CheckVal value={coupon.bonus_no_purchase||0} onChange={this.changeProp.bind(this,"bonus_no_purchase",coupon.bonus_no_purchase?0:1)} label="Don't require a new purchase"/>:null}
                    </div>:null}
                    {bonusProduct?.purchaseType=="subscription"?<div>
                        <CheckVal value={coupon.bonus_trial||0} onChange={this.changeProp.bind(this,"bonus_trial",coupon.bonus_trial?0:1)} label="Trial Subscription"/>&nbsp;&nbsp;
                        <SelectVal helperText="Subscription Months" value={coupon.bonus_months||0} values={zeroTo20} onClick={this.changeProp.bind(this,"bonus_months")}/>
                    </div>:null}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.setShowShare.bind(this,true)} color="primary">
                    Get Share Link
                </Button>
                <Button onClick={this.showPickProducts.bind(this)} color="primary">
                    Pick Products
                </Button>
                <Button onClick={this.save.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>:null}
            <ProductsPicker open={this.state.showPick} selectOwned={!marketplace.isAdmin()} dateSort={marketplace.isAdmin()} selected={selected} onClose={this.pickProducts.bind(this)}/>
            <ProductsPicker open={this.state.showBonus} single selectOwned={!marketplace.isAdmin()} dateSort={marketplace.isAdmin()} selected={selectedBonus} onClose={this.pickBonusProduct.bind(this)}/>
            <GetShareLink open={this.state.showShare} onClose={this.setShowShare.bind(this,false)} page={page}/>
        </Dialog>;
    }

    setShowShare(showShare) {
        this.setState({showShare})
    }

    showPickProducts(){
        this.setState({showPick:true});
    }

    showPickBonus() {
        this.setState({showBonus:true});
    }

    pickProducts(products) {
        if (products) {
            const coupon = Object.assign({},this.state.coupon);
            const keys = Object.keys(products);

            if (keys.length) {
                coupon.products = keys.join(",");
            } else {
                coupon.products = null;
                if (coupon.bonus_requirement=="All") {
                    coupon.bonus_requirement = "Any";
                }
            }
            this.setState({coupon});
        }
        this.setState({showPick:false});
    }

    pickBonusProduct(products) {
        if (products) {
            const keys = Object.keys(products);

            const coupon = Object.assign({},this.state.coupon);
            if (keys.length) {
                coupon.bonus_product = keys[0];
                const product = (marketplace.products||{})[coupon.bonus_product];
                if (product?.purchaseType=="subscription") {
                    coupon.bonus_months = 3;
                    coupon.bonus_trial = 1;
                } else {
                    coupon.bonus_months = 0;
                    coupon.bonus_trial = 0;
                }

            } else {
                delete coupon.bonus_product;
                coupon.bonus_months = 0;
                coupon.bonus_trial = 0;
            }
            this.setState({coupon});
        }
        this.setState({showBonus:false});
    }

    changeProp(prop,val) {
        const coupon = Object.assign({},this.state.coupon);
        coupon[prop]=val;
        this.setState({coupon});
    }

    async save() {
        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=updatecoupon", JSON.stringify(this.state.coupon));
            this.props.onClose();
        } catch (err) {
            console.log("error saving coupon", err);
            displayMessage("Error saving coupon: "+err.message);
        }
        this.setState({loading:false});
    }

    async selectFile(e) {
        if (e.target.files.length == 1) {
            // upload files
            try {
                const storage = getStorage(firebase);

                this.setState({loading:true});

                const file = e.target.files[0];
                const path = "users/"+campaign.userId+"/publisher/coupon_"+campaign.newUid();

                const imageInfo = await resizeAndCropImage(file, 1024,512)
                const blob=imageInfo.blob;
                const fileRefThumb = ref(storage,path+".png");

                await uploadBytes(fileRefThumb, blob, {contentType:"image/png",cacheControl: "public,max-age=4838400"});
                const downloadThumbURL = getDirectDownloadUrl(path+".png");
                this.changeProp("image", downloadThumbURL);
                this.setState({loading:false});
            } catch(err){
                this.errorSelectingFile("Error getting thumbnail file information", err);
            };
        }
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+"\nError:"+err);
        console.log(msg, err);
        this.setState({loading:false});
    }
}

class SendGifts extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false,products:{},email:"",subject:"", body:"", yearly:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({products:{},email:"",subject:"", body:"", yearly:false})
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const products = marketplace.products||{};
        const selected = this.state.products;
        const plist = [];
        for (let i in selected) {
            if (products[i]) {
                plist.push(products[i].displayName)
            }
        }

        return <Dialog
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Send Gifts</DialogTitle>
            <DialogContent>
                <TextVal className="mb1" text={this.state.email||""} fullWidth onChange={this.changeProp.bind(this, "email")} helperText="Email List (comma separated)"/>
                <TextVal className="mb1" multiline text={this.state.subject||""} fullWidth onChange={this.changeProp.bind(this, "subject")} helperText="Subject"/>
                <TextVal className="mb1" multiline text={this.state.body||""} fullWidth onChange={this.changeProp.bind(this, "body")} helperText="Body"/>
                <CheckVal value={this.state.yearly||false} onChange={this.changeProp.bind(this, "yearly")} label="Year long subscription"/>
                <div className="mv1">
                    <b>Selected Products: </b> {plist.join(", ")}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.changeProp.bind(this,"showPick", true)} color="primary">
                    Pick Products
                </Button>
                <Button onClick={this.send.bind(this)} color="primary">
                    Send
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Sending...
                </DialogContent>
            </Dialog>:null}
            <ProductsPicker open={this.state.showPick} selected={this.state.products} onClose={this.pickProducts.bind(this)}/>
        </Dialog>;
    }

    pickProducts(products) {
        if (products) {
            this.setState({products, showPick:false});
        } else {
            this.setState({showPick:false});
        }
    }

    changeProp(prop,val) {
        const set = {};
        set[prop]=val;
        this.setState(set);
    }

    async send() {
        const t=this;
        const allproducts = marketplace.products||{};
        const selected=this.state.products;
        const yearly=this.state.yearly||false;
        const emailList = (this.state.email||"").split(",");
        const succeeded = [];
        const failed = [];
        const products=[];
        for (let i in selected) {
            products.push({id:i, yearly});
        }
        products.sort(function (a,b){ return (allproducts[a.id].displayName||"").toLowerCase().localeCompare((allproducts[b.id].displayName||"").toLowerCase())});
    
        this.setState({loading:true});
        for (let i in emailList) {
            const email=emailList[i].trim();
            if (email) {
                try {
                    await httpAuthRequestWithRetry("POST", "/search?cmd=adminsendgift", JSON.stringify({
                        email,
                        subject:this.state.subject,
                        textBody:this.state.body,
                        products
                    }));
                    succeeded.push(email);
                } catch (err) {
                    failed.push(email);
                    console.log("error sending gifts", err);
                }
            }
        }
        if (!succeeded.length && !failed.length) {
            displayMessage("no email addresses");
        } else if (succeeded.length && !failed.length) {
            displayMessage("Gifts sent", function (){t.props.onClose()});
        } else if (failed.length) {
            displayMessage("Some recipients failed.  The email list has been updated with failed email addresses.");
            this.setState({email:failed.join(", ")});
        }
        this.setState({loading:false});
    }
}

class SendPublisherEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false,subject:"", replyToEmail:campaign.email, body:{html:""}, richEdit:true, imageUrl:null};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({products:{},subject:"", replyToEmail:campaign.email, body:{html:""}, richEdit:true, imageUrl:null})
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Send Publisher Email</DialogTitle>
            <DialogContent>
                <TextVal className="mb1" text={this.state.replyToEmail||""} fullWidth onChange={this.changeProp.bind(this, "replyToEmail")} helperText="Reply To Email Address"/>
                <TextVal className="mb1" multiline text={this.state.subject||""} fullWidth onChange={this.changeProp.bind(this, "subject")} helperText="Subject"/>
                <div className="mb1">
                    <span className="b f3">Message Body </span>
                    <SelectVal value={this.state.richEdit?"Rich Edit":"HTML Edit"} values={["Rich Edit","HTML Edit"]} onClick={this.changeRichEdit.bind(this)}/>
                </div>
                {this.state.richEdit?<div>
                    <div className="tc">
                        <Button onClick={this.showPickArt.bind(this)} color="primary" variant="outlined">
                            Pick Image
                        </Button>
                    </div>
                    {this.state.imageUrl?<table align="center" width="100%"><tbody><tr><td align="center"><img style={{maxWidth:"100%"}} src={this.state.imageUrl}/></td></tr></tbody></table>:null}
                    <EntityEditor onChange={this.changeProp.bind(this, "body")} entry={this.state.body} noSearch/>
                </div>
                :
                    <TextVal className="mb1" multiline text={this.state.body.html} fullWidth onChange={this.changeHtmlBody.bind(this)} helperText="html"/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={this.send.bind(this,true)} color="primary">
                    Send Test
                </Button>
                <Button onClick={this.showConfirm.bind(this,false)} color="primary">
                    Send
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Sending...
                </DialogContent>
            </Dialog>:null}
            <ArtPicker defaultType="Picture" open={this.state.showPickArt} onClose={this.onClosePick.bind(this)}/>
            <AskYesNo open={this.state.readyToSend} onClose={this.confirmSend.bind(this)} text="Are you ready to send email to everyone that has purchased or been gifted one of your products?"/>
        </Dialog>;
    }

    showConfirm() {
        this.setState({readyToSend:true});
    }

    confirmSend(yes) {
        this.setState({readyToSend:false});
        if (yes) {
            this.send(false);
        }
    }

    showPickArt(){
        this.setState({showPickArt:true});
    }

    onClosePick(art) {
        this.setState({showPickArt:false});
        if (art) {
            this.setState({imageUrl:art.url});
        }
    }

    changeRichEdit(val) {
        this.setState({richEdit:val=="Rich Edit"});
    }

    changeHtmlBody(html) {

        this.setState({body:{type:"html",html}});
    }

    changeProp(prop,val) {
        const set = {};
        set[prop]=val;
        this.setState(set);
    }

    async send(testEmail) {
        this.setState({loading:true});
        const html = this.state.body.html;
        const htmlContent = this.state.richEdit?(emailRichPre+(this.state.imageUrl?('<table align="center" width="100%"><tbody><tr><td align="center"><img style="max-width:100%" src="'+this.state.imageUrl+'"/></td></tr></tbody></table>'):"")+html+emailRichPost):html;
        //console.log("body", htmlContent);
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=publishersend", JSON.stringify({
                subject:this.state.subject, htmlContent, replyToEmail:this.state.replyToEmail, testEmail
            }));
            displayMessage(testEmail?"Test email sent to your email address.":"Email scheduled to send.");
        } catch (err) {
            console.log("error sending email", err);
            displayMessage("Error sending email. "+err.message);
        }
        this.setState({loading:false});
    }

}

//const emailRichPre ='<table border="0" align="center" width="100%" cellpadding="0" cellspacing="0" class="email" bgcolor="#eee7cd" style="background-color:#eee7cd"><tbody><tr><td align="left" valign="top">';
const emailRichPre = "<style>"+
    ".email {font-family: 'Georgia', serif;line-height: 1.4;font-size: 14px;color:rgba(0, 0, 0, 0.87);background-color: #eee7cd;white-space: pre-wrap;} "+
    ".email h1 {color:#58170D;font-variant: small-caps;font-size: 22px;font-weight: bold;margin-bottom: 8px;margin-top: 8px;} "+
    ".email h2 {color:#58170D;font-variant: small-caps;font-size: 17px;font-weight: bold;border-color:#58170D;border-bottom-style: solid;border-bottom-width: 1px;margin-bottom: 2px;margin-top: 4px;} "+
    ".email h3 {color:#58170D;font-variant: small-caps;font-size: 15px;font-weight: bold;margin-bottom: 4px;margin-top: 4px;} "+
    ".email h4 {font-variant: small-caps;font-weight: bold;font-size: 14px;color:rgba(0, 0, 0, 0.87);margin-bottom: 4px;margin-top: 4px;font-family: 'Tahoma',Geneva,sans-serif,sans-serif} "+
    ".email h5 {font-size: 14px;color:rgba(0, 0, 0, 0.87);font-style: italic;margin-bottom: 4px;margin-top: 4px;font-family: 'Tahoma',Geneva,sans-serif,sans-serif} "+
    ".email h6 {font-size: 13px;color:rgba(0, 0, 0, 0.87);font-style: italic;margin-bottom: 4px;margin-top: 4px;font-family: 'Tahoma',Geneva,sans-serif,sans-serif} "+
    ".email p {margin-bottom: 7px;margin-top: 1px;} "+
    ".email ul {margin-bottom: 8px;margin-top: 4px;padding-left: 15px;} "+
    ".email li {margin-bottom: 1px;margin-top: 1px;} "+
    ".email table {overflow: auto;border-collapse: collapse;background-color: transparent; font-family: 'Tahoma',Geneva,sans-serif; width:100%} "+
    ".email figure {width:100%;margin: 0em 0px;} "+
    ".email tr {margin-top:1px;margin-bottom:1px;} "+
    ".email tr:nth-child(even) {background-color: rgba(203, 191, 170, 0.5);} "+
    ".email td {padding-left:2px;padding-right:2px;font-family: 'Tahoma',Geneva,sans-serif,sans-serif} "+
    ".email th {padding-left:2px;padding-right:2px;font-family: 'Tahoma',Geneva,sans-serif,sans-serif} "+
    ".email blockquote {font-family: 'Tahoma',Geneva,sans-serif,sans-serif;background-color: #e9ecda;box-shadow: 0 2px 4px 1px rgba(0,0,0,0.2);margin: 8px;padding: 8px;overflow: hidden;} "+
    ".email blockquote h3 {color:rgba(0, 0, 0, 0.87);} "+
    ".templateContainer {max-width: 590px!important;width: auto!important;} "+
    "@media only screen and (min-width:590px){.templateContainer {width:590px!important}} "+
    "</style> "+
    '<table border="0" align="center" width="100%" cellpadding="0" cellspacing="0" bgcolor="#eee7cd"><tbody><tr><td align="center" valign="top">'+
    '<table border="0" cellpadding="0" cellspacing="0" width="590" class="templateContainer" style="max-width:590px!important; width:590px"><tbody><tr><td align="center" valign="top"><div  class="email" style="text-align:left">';
const emailRichPost = '</div></td></tr></tbody></table></td></tr></tbody></table>'

class MarketProductDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({products:marketplace.products, cart:marketplace.cart});
    }

    componentDidMount() {
        globalDataListener.onChangeProducts(this.handleOnDataChange);
        globalDataListener.on("cart",this.handleOnDataChange);
    }

    componentWillUnmount() {
        globalDataListener.removeProductsListener(this.handleOnDataChange);
        globalDataListener.removeListener("cart",this.handleOnDataChange);
    }

	render() {
        const product = marketplace.getProductInfo(this.props.id);

        return <div className="w-100 notecontent mw9 pa1" key="product">
            <ProductDisplay product={product} onClickPublisher={this.props.onClickPublisher}/>
        </div>;
    }
}

const productFilters = [
    {
        filterName:"Publish Type",
        fieldName:"publishState",
    },
    {
        filterName:"State",
        fieldName:"needsUpdate",
        convertField(f,product) {
            if (!marketplace.loaded) {
                return "unknown";
            }
            const pp = (marketplace.products||{})[product.name];
            if (!pp) {
                return "Unpublished";
            }
            return isDirtyProduct(product)?"Needs update":"Published";
        } 
    },
    {
        filterName:"Category",
        fieldName:"type",
        advancedOnly:true,
    },
    {
        filterName:"Theme",
        fieldName:"genre",
        advancedOnly:true,
    },
    {
        filterName:"Setting",
        fieldName:"setting",
        advancedOnly:true,
    },
    {
        filterName:"Storyline",
        fieldName:"storyline",
        advancedOnly:true,
    },
];

const productPickFilters = [
    {
        filterName:"Category",
        fieldName:"type",
    },
    {
        filterName:"Theme",
        fieldName:"genre",
    },
    {
        filterName:"Setting",
        fieldName:"setting",
    },
    {
        filterName:"Storyline",
        fieldName:"storyline",
    },
    {
        filterName:"Publisher",
        fieldName:"publisher",
    },
];

class ShardSubscriptions extends React.Component {
	render() {
        return <div className="stdcontent overflow-x-auto">
            <div className="minw7">
                <div className="titlebackground titlecolorcontrast titletext f1 tc pa1">Subscription Comparison</div>
                <table>
                    <tbody>
                        <tr><td></td><td className="tc f3"><b>&nbsp;&nbsp;&nbsp;Free&nbsp;&nbsp;&nbsp;</b></td><td className="tc f3"><b><a onClick={this.clickLink.bind(this,"/marketplace#product?id=1lptzx3bblfjy4bm")}>Adventurer</a></b></td><td className="tc f3"><b><a onClick={this.clickLink.bind(this,"/marketplace#product?id=0mgps243ynv32dvk")}>Gamemaster</a></b></td><td className="tc f3"><b><a onClick={this.clickLink.bind(this,"/marketplace#product?id=2yarsenaw01v6pj7")}>Gamemaster&nbsp;Pro</a></b></td></tr>
                        <tr><td><b>Characters</b></td><td className="tc">6</td><td className="tc">Unlimited</td><td className="tc">Unlimited</td><td className="tc">Unlimited</td></tr>
                        <tr><td><b>Campaigns</b></td><td className="tc">1</td><td className="tc">2</td><td className="tc">6</td><td className="tc">Unlimited</td></tr>
                        <tr><td><b>5E SRD+ Content</b></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td></tr>
                        <tr><td><b><a href="https://shardtabletop.com/core-content-pack">Core Content Pack</a></b></td><td className="tc">-</td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td></tr>
                        <tr><td><b>Token Elements</b></td><td className="tc">Basic</td><td className="tc">Premium</td><td className="tc">Premium</td><td className="tc">Premium +<br/>Create your own</td></tr>
                        <tr><td><b>Digital Dice</b></td><td className="tc">Basic</td><td className="tc">Premium</td><td className="tc">Premium</td><td className="tc">Premium +<br/>Create your own</td></tr>
                        <tr><td><b><a href="https://shardtabletop.com/howto/what-character-sheet-styles-are-available">Character Sheet Styles</a></b></td><td className="tc">Basic</td><td className="tc">Premium</td><td className="tc">Premium</td><td className="tc">Premium</td></tr>
                        <tr><td><b>Access to Free Content</b></td><td className="tc">4/month</td><td className="tc">Unlimited</td><td className="tc">Unlimited</td><td className="tc">Unlimited</td></tr>
                        <tr><td><b>Second Screen Support</b></td><td className="tc">-</td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td></tr>
                        <tr><td><b>Package Sharing</b></td><td className="tc">-</td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td></tr>
                        <tr><td><b>Watch Mode</b></td><td className="tc">-</td><td className="tc">-</td><td className="tc">Broadcast</td><td className="tc">Interactive</td></tr>
                        <tr><td><b><a href="https://shardtabletop.com/howto/player-content-sharing">Player Library Sharing</a></b></td><td className="tc">1*</td><td className="tc">3*</td><td className="tc">5</td><td className="tc">5</td></tr>
                        <tr><td><b>Book Export & Printing</b></td><td className="tc"></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td><td className="tc"><ShardSubscriptionsCheck/></td></tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="tc f3 pv1"><Button onClick={this.clickLink.bind(this,"/marketplace#product?id=1lptzx3bblfjy4bm")} color="primary" variant="outlined" size="small">Subscribe Adventurer</Button></td>
                            <td className="tc f3 pv1"><Button onClick={this.clickLink.bind(this,"/marketplace#product?id=0mgps243ynv32dvk")} color="primary" variant="outlined" size="small">Subscribe Gamemaster</Button></td>
                            <td className="tc f3 pv1"><Button onClick={this.clickLink.bind(this,"/marketplace#product?id=2yarsenaw01v6pj7")} color="primary" variant="outlined" size="small">Subscribe Gamemaster&nbsp;Pro</Button></td>
                        </tr>
                    </tbody>
                </table>
                <div>* The campaign creator must have a Gamemaster or Gamemaster Pro subscription for Free and Adventurer subscribers to contribute content as a player.  Gamemaster and Gamemaster Pro subscribers do not have this restriction.</div>
            </div>
        </div>;
    }

    clickLink(url) {
        if (this.props.clickLink) {
            this.props.clickLink(url);
        } else {
            window.location.href=url;
        }
    }
}

class ShardSubscriptionsDialog extends React.Component {
	render() {
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogContent>
                <ShardSubscriptions clickLink={this.clickLink.bind(this)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    clickLink(url) {
        this.props.onClose();
        window.location.href=url;
    }
}

class ShardSubscriptionsCheck extends React.Component {
	render() {
        return <span className="f1 fas fa-check b dark-green"/>;
    }
}

class GiftProduct extends React.Component {
    constructor(props) {
        super(props);
        const t=this;

        this.state={};
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.open != this.props.open) && this.props.open) {
            const n = getDateStrFromDate(new Date());
            this.setState({email:null, endDate:n});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const product = this.props.product;

        return <Dialog 
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Gift Product {product.displayName}</DialogTitle>    
            <DialogContent>
                <TextVal text={this.state.email||""} onChange={this.updateEmail.bind(this)} fullWidth helperText="Email Address"/>
                {(product.purchaseType=="subscription")?<DateVal className="ml2" date={this.state.endDate} onChange={this.onChangeDate.bind(this)} label="end"/>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onGift.bind(this)} color="primary">
                    Gift
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Gifting Product...
                </DialogContent>
            </Dialog>
        </Dialog>
    }

    async onGift() {
        const product = this.props.product;
        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=admingiftproduct", JSON.stringify({productId:product.name, email:this.state.email, endDate:this.state.endDate, purchaseType:product.purchaseType}));
            displayMessage("Product gifted");
            this.props.onClose();
        } catch (error) {
            displayMessage("Could not gift the product: "+error.message);
        }

        this.setState({loading:false});

    }

    onChangeDate(endDate) {
        this.setState({endDate});
    }

    updateEmail(email){
        this.setState({email});
    }
}

class GetShareLink extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && this.props.open != prevProps.open) {
            this.setState({refc:this.props.page.refc||""});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const page = Object.assign({}, this.props.page);
        let params = []

        let navurl = `https://marketplace.shardtabletop.com/m`;
        if (page.page=="product") {
            navurl = `${navurl}/product`
        }
        delete page.page;
        delete page.hash;
        if (this.state.refc && marketplace.publisher) {
            page.refc=this.state.refc;
            page.refu=marketplace.userId;
        } else {
            delete page.refc;
            delete page.refu;
        }
        for (let i in page) {
            params.push(`${i}=${encodeURIComponent(page[i])}`);
        }
        if (params.length) {
            navurl=`${navurl}?${params.join("&")}`;
        }
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.props.onClose}>Marketplace Link</DialogTitle>
            <DialogContent>
                <div className="titlecolor f3">
                    <div>Get link to page for sharing</div>
                    {marketplace.publisher?<TextVal fullWidth text={this.state.refc} onChange={this.setRefc.bind(this)} helperText="Tracking Code"/>:null}
                    <ClipboardCopy text={navurl}>{navurl}</ClipboardCopy>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    setRefc(refc) {
        this.setState({refc});
    }
}

class AssignStuff extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

	render() {
        const {showSetAssign, showProductsPicker, showPackagePicker, assignEmail, assignText} = this.state;
        return <div>
            <div>
                <Button color="primary" variant="outlined" size="small" onClick={this.showSetAssign.bind(this)}>Set assign Email</Button> {assignEmail}
            </div>
            {assignEmail?<div className="mv2">
                <Button className="ml2" color="primary" variant="outlined" size="small" onClick={this.showPickProducts.bind(this)}>Assign Products</Button>
                <Button className="ml2" color="primary" variant="outlined" size="small" onClick={this.showPickPackages.bind(this)}>Assign Packages</Button>
            </div>:null}
            <TextBasicEdit show={this.state.showSetAssign} onChange={this.doSetAssign.bind(this)} label="Assign to Email" info="Enter the email address of the account to make assignments"/>
            <ProductsPicker open={this.state.showProductsPicker} onClose={this.pickProducts.bind(this)}/>
            <AdminPackageDialog select open={this.state.showPackagePicker} onClose={this.closePackagePicker.bind(this)}/>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Doing assign {assignText}
                </DialogContent>
            </Dialog>

        </div>;
    }

    showSetAssign() {
        this.setState({showSetAssign:true});
    }

    doSetAssign(assignEmail) {
        if (assignEmail) {
            this.setState({assignEmail});
        }
        this.setState({showSetAssign:false});
    }

    showPickProducts() {
        this.setState({showProductsPicker:true});
    }

    async pickProducts(selected) {
        this.setState({showProductsPicker:false});
        if (selected) {
            const {assignEmail} = this.state;
            const products = marketplace.products||{};

            //console.log("picked products", selected);
            for (let p in selected) {
                const product = products[p];
                this.setState({loading:true, assignText:"product "+product?.displayName +" to "+assignEmail});
                try {
                    await httpAuthRequestWithRetry("POST", "/search?cmd=assignproduct", JSON.stringify({productId:p,email:assignEmail}));
                    //console.log("did assign",p)
                } catch (error) {
                    window.alert("Could not assign the product: "+error.message);
                }
            }
    
            this.setState({loading:false});
        }

    }

    showPickPackages() {
        this.setState({showPackagePicker:true});
    }

    async closePackagePicker(selected) {
        this.setState({showPackagePicker:false});
        if (selected) {
            const {assignEmail} = this.state;

            //console.log("picked packages", selected);
            for (let p in selected) {
                const pkg = campaign.getPackageInfo(p);
                this.setState({loading:true, assignText:"package "+(pkg?.name || p) +" to "+assignEmail});
                try {
                    await httpAuthRequestWithRetry("POST", "/search?cmd=assignpackage", JSON.stringify({packageId:p,email:assignEmail}));
                    //console.log("did assign",p)
                } catch (error) {
                    window.alert("Could not assign the package: "+error.message);
                }
            }
    
            this.setState({loading:false});
        }

    }
}


export {
    ProductsHeader,
    ProductsList,
    ProductDetails,
    MarketProductDetails,
    ProductDisplay,
    AddProductKey,
    AddGift,
    UnlockKeys,
    ProductDialog,
    ShardSubscriptions,
    ShardSubscriptionsDialog,
    ConfigurePublisherInfo,
    SendGifts,
    SendPublisherEmail,
    CouponsDialog,
    GetShareLink,
    AssignStuff
}